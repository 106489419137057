import styled from '@emotion/styled';
import { mdiLogout } from '@mdi/js';
import {
  Avatar,
  CircularProgress,
  IconButton,
  ListItemIcon,
  Menu,
  MenuItem,
} from '@mui/material';
import { useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import { Divider } from '../../../../components/Divider';
import { MdiIcon } from '../../../../components/icons';
import { authAction } from '../../../../helper/auth';
import { useProfile } from '../../../../hooks/user';
import { RootState } from '../../../../redux';
import { AuthActions } from '../../../../redux/auth';
import { capacityFirst } from '../../../../utils/string';
import { getDisplayNameFromUser } from '../../../../utils/user';

type Props = {};

export default function ProfileButton(props: Props) {
  const profile = useProfile();
  const accountRef = useRef(null);
  const dispatch = useDispatch();
  const [menuOpen, setMenuOpen] = useState(false);
  const { t } = useTranslation();
  const auth = useSelector((state: RootState) => state.auth);

  const isAuthenticating = auth.isAuthenticating;
  const isAuthed = !!auth.firebaseUser && !!auth.mindfullyAuth;

  const displayName = getDisplayNameFromUser(
    profile,
    capacityFirst(t('login'))
  );

  const logout = () => {
    dispatch(AuthActions.logout());
  };

  if (isAuthenticating || (isAuthed && !profile)) {
    return (
      <div
        style={{
          alignSelf: 'center',
          minWidth: 32,
          textAlign: 'center',
          minHeight: 32,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={16} color="inherit" />
      </div>
    );
  }

  return (
    <>
      {isAuthed ? (
        <Root
          onClick={() => authAction(() => setMenuOpen(true))}
          ref={accountRef}
        >
          <StyledAvatar src={profile?.photoURL ? profile?.photoURL : undefined}>
            {displayName?.[0]}
          </StyledAvatar>
        </Root>
      ) : (
        <Button
          color="inherit"
          onClick={() => authAction(() => setMenuOpen(true))}
        >
          <span style={{ fontSize: 14 }}>
            <Trans>Login</Trans>
          </span>
        </Button>
      )}
      {!!profile && (
        <Menu
          anchorEl={accountRef.current}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          PaperProps={{
            elevation: 0,
            sx: {
              background: '#172B2A',
              color: 'inherit',
              overflow: 'visible',
              filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
              mt: 1.5,
              '& .MuiAvatar-root': {
                width: 32,
                height: 32,
                ml: -0.5,
                mr: 1,
              },
            },
          }}
          open={menuOpen}
          onClose={() => setMenuOpen(false)}
        >
          <div className="pv-8 ph-16">
            <div>
              <span className="text text-bold">{displayName}</span>
            </div>
            {!!profile?.email && (
              <div>
                <span className="text text-small text-light">
                  {profile?.email}
                </span>
              </div>
            )}
          </div>
          <Divider />
          {/* <MenuItem
          onClick={() => {
            navigate('/me/profile');
            setMenuOpen(false);
          }}
        >
          <ListItemIcon>
            <MdiIcon d={mdiAccount} />
          </ListItemIcon>
          <span className="text text-small text-medium">My Profile</span>
        </MenuItem> */}
          <MenuItem
            onClick={() => {
              setMenuOpen(false);
              logout();
            }}
          >
            <ListItemIcon
              sx={{
                color: '#cc4040',
              }}
            >
              <MdiIcon d={mdiLogout} color="inherit" />
            </ListItemIcon>
            <span
              className="text text-small text-medium"
              style={{ color: '#cc4040' }}
            >
              <Trans>Logout</Trans>
            </span>
          </MenuItem>
        </Menu>
      )}
    </>
  );
}

const Root = styled(IconButton)`
  display: flex;
  align-items: center;
  color: inherit;
  align-self: center;
  font-size: 18px;
  font-weight: 600;
  padding: 0;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const StyledAvatar = styled(Avatar)`
  width: 36px;
  height: 36px;
  pointer-events: none;

  @media (max-width: 600px) {
    width: 28px;
    height: 28px;
  }
`;
