import styled from '@emotion/styled';
import React from 'react';
import { connect } from 'react-redux';
import { Dispatch } from 'redux';
import MusicPlayer from '../../../../controllers/MusicPlayer';
import { useIsMobile } from '../../../../hooks/layout';
import { RootState } from '../../../../redux';
import { MeditationActions } from '../../../../redux/meditation';
import TabItem from './TabItem';

const itemWidth = 96;

type Props = { isMobile?: boolean; dispatch: Dispatch } & ReturnType<
  typeof mapStateToProps
>;

type State = {
  isTouched: boolean;
};

const initState = Object.freeze<State>({
  isTouched: false,
});

const mapStateToProps = (state: RootState) => ({
  meditation: state.meditation,
});

class TabsComponent extends React.Component<Props, State> {
  private _rootRef = React.createRef<HTMLDivElement>();
  private _isMouseDown = false;
  private _t?: number;
  private _itemClicked = false;

  state = initState;

  componentDidMount() {
    this._mobileScreenEffect();
  }

  componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<{}>,
    snapshot?: any
  ): void {
    if (prevProps.isMobile !== this.props.isMobile) {
      this._mobileScreenEffect();
    }
  }

  private _mobileScreenEffect() {
    const { meditation, isMobile } = this.props;
    if (isMobile) {
      const currentIndex = meditation.topics.findIndex(
        (t) => t.id === meditation.currentTopic
      );
      if (currentIndex >= 0) {
        this._rootRef.current?.scroll({ left: itemWidth * currentIndex });
      }
    }
  }

  private _handleMouseDown = () => {
    this._isMouseDown = true;
    this.setState({ isTouched: true });
    // window.addEventListener('mouseup', this._handleMouseUp);
    window.addEventListener('touchend', this._handleMouseUp);
  };

  private _handleMouseUp = () => {
    this._isMouseDown = false;

    window.setTimeout(() => {
      if (this._itemClicked) {
        this._itemClicked = false;
        if (this.state.isTouched) {
          this.setState({ isTouched: false });
        }

        return;
      }
      this._onSnap();
    });
    // window.removeEventListener('mouseup', this._handleMouseUp);
    window.removeEventListener('touchend', this._handleMouseUp);
  };

  private _handleScroll: React.UIEventHandler = (e) => {
    if (!this._isMouseDown) {
      this._onSnap();
    }
  };

  private _onSnap() {
    if (this._t) {
      window.clearTimeout(this._t);
    }
    this._t = window.setTimeout(() => {
      if (this.state.isTouched) {
        this.setState({ isTouched: false });
      }
      if (!this.props.isMobile) {
        return;
      }
      const rootEl = this._rootRef.current;
      if (rootEl) {
        const idx = Math.floor(rootEl.scrollLeft / itemWidth);
        this._changeToppic(this.props.meditation.topics[idx]?.id);
      }
      this._t = undefined;
    }, 200);
  }

  private _handleItemClick = (topicId: number, idx: number) => {
    this._itemClicked = true;
    this._changeToppic(topicId);
    this._rootRef.current?.scroll({ left: itemWidth * idx });
  };

  private _changeToppic(topicId: number) {
    MusicPlayer.setMedio();
    this.props.dispatch(MeditationActions.setCurrentTopic(topicId));
  }

  render(): React.ReactNode {
    const { meditation } = this.props;

    return (
      <Root
        isTouched={this.state.isTouched}
        isMobile={!!this.props.isMobile}
        onContextMenu={(e) => e.preventDefault()}
      >
        <TabRoot
          className="tab-root"
          ref={this._rootRef}
          onScroll={this.props.isMobile ? this._handleScroll : undefined}
          // onPointerDown={this._handleMouseDown}
          // onMouseDown={this._handleMouseDown}
          onTouchStart={this._handleMouseDown}
        >
          <Cushion />
          {meditation.topics.map((v, idx) => (
            <TabItem
              key={v.id}
              label={v.name.toLowerCase()}
              selected={v.id === meditation.currentTopic}
              onClick={() => this._handleItemClick(v.id, idx)}
              icon={v.thumbnailUrl}
            />
          ))}
          <Cushion />
        </TabRoot>
      </Root>
    );
  }
}

function withIsMobile<T extends { isMobile?: boolean }>(
  component: React.ComponentType<T>
) {
  const Component = component;
  return (p: T) => {
    const isMobile = useIsMobile();
    const props = {
      ...p,
      isMobile,
    } as T;

    return <Component {...props} />;
  };
}

export default connect(mapStateToProps)(withIsMobile(TabsComponent));

type RootProps = {
  isMobile: boolean;
  isTouched: boolean;
};

const Root = styled.div<RootProps>`
  /* overflow: hidden; */
  position: relative;

  :before {
    content: '';
    position: absolute;
    display: ${({ isMobile }) => (isMobile ? 'block' : 'none')};
    top: 8px;
    bottom: 8px;
    left: calc(50% - ${itemWidth / 2 - 6}px);
    right: calc(50% - ${itemWidth / 2 - 6}px);
    background-color: rgba(213, 213, 213, 0.04);
    border-radius: 8px;
    box-sizing: border-box;
    opacity: ${({ isTouched }) => (isTouched ? 1 : 0)};
    transition: opacity ${({ isTouched }) => (isTouched ? 300 : 500)}ms
      ease-in-out;
  }
`;

const TabRoot = styled.div`
  display: flex;
  width: auto;
  box-sizing: content-box;
  scroll-behavior: smooth;
  @media (max-width: 600px) {
    overflow: scroll;
    /* flex: 1; */
    min-width: 0;
    /* max-width: 400px; */
    scroll-snap-type: mandatory;
    scroll-snap-points-x: repeat(${itemWidth}px);
    scroll-snap-type: x mandatory;
    mask-image: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.1) 0%,
      rgba(0, 0, 0, 1) 25%,
      rgba(0, 0, 0, 1) 75%,
      rgba(0, 0, 0, 0.1) 100%
    );
  }

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Cushion = styled.div`
  @media (max-width: 600px) {
    min-width: calc(50% - ${itemWidth / 2}px);
  }
`;
