import styled from '@emotion/styled';
import { mdiClose } from '@mdi/js';
import { Dialog } from '@mui/material';
import { Trans } from 'react-i18next';
import Button from '../../../../../components/Button';
import {
  ChevronDownIcon,
  MdiIcon,
  PauseIcon,
  PlayIcon,
} from '../../../../../components/icons';
import MusicPlayer from '../../../../../controllers/MusicPlayer';
import { useIsMobile } from '../../../../../hooks/layout';
import {
  useCurrentTopic,
  useSetCurrentTopic,
  useSetTopicDetail,
  useTopicDetail,
} from '../../../../../hooks/meditation';
import { useCurrentMedio, usePlayerState } from '../../../../../hooks/player';
import { StyledIconButton } from '../../library/styled';

export default function TopicDetail() {
  const isMobile = useIsMobile();
  const currentMedio = useCurrentMedio();
  const currentTopic = useCurrentTopic();
  const setCurrentTopic = useSetCurrentTopic();
  const topicDetail = useTopicDetail();
  const setTopicDetail = useSetTopicDetail();
  const playerState = usePlayerState();

  const isPlaying = !currentMedio && topicDetail?.id === currentTopic?.id;

  const PlayerIcon = isPlaying ? PauseIcon : PlayIcon;

  const onClose = () => {
    setTopicDetail();
  };

  return (
    <Dialog
      open={!!topicDetail}
      onClose={onClose}
      fullScreen={isMobile}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 760,
          background: 'transparent',
          color: 'inherit',
          boxShadow: 'none',
          position: 'relative',
        },
      }}
    >
      <ClosePane>
        <Button sx={{ p: 1, minWidth: 0 }} color="inherit" onClick={onClose}>
          {isMobile ? <ChevronDownIcon /> : <MdiIcon d={mdiClose} />}
        </Button>
      </ClosePane>
      <Container>
        <BackgroundImage src={topicDetail?.thumbnailUrl} />
        <ContentContainer>
          <Title>
            <Trans>{topicDetail?.name}</Trans>
          </Title>
          <MetaContainer>
            <div className="buttons-pane">
              <StyledIconButton
                style={{ marginTop: 0, marginBottom: 0 }}
                color="primary"
                size="large"
                onClick={() => {
                  if (!topicDetail) {
                    return;
                  }
                  if (!isPlaying) {
                    setCurrentTopic(topicDetail.id);
                    MusicPlayer.playTopicMusic(topicDetail.id);
                    return;
                  }
                  if (playerState === 'play') {
                    MusicPlayer.pause();
                    return;
                  }
                  MusicPlayer.play();
                }}
              >
                <PlayerIcon fontSize="inherit" />
              </StyledIconButton>
            </div>
          </MetaContainer>
          <ScrollView>
            <Description>
              <div style={{ padding: '20px 0' }}>
                {topicDetail?.description}
              </div>
            </Description>
          </ScrollView>
        </ContentContainer>
      </Container>
    </Dialog>
  );
}

const ClosePane = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    position: absolute;
    z-index: 10;
  }
`;

const Container = styled.div`
  /* padding: 16px; */

  background: #172b2a;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(23, 43, 42, 0.2);
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;

  @media (min-width: 600px) {
    border-radius: 16px;
    /* height: 90vh;
    max-height: 600px; */
  }
`;

const BackgroundImage = styled.div<{ src?: string }>`
  width: 100%;
  height: 285px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  :after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(23, 43, 42, 0) 50%, #172b2a 100%);
  }
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  padding-right: 260px;
  padding-left: 40px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.8);
  padding-right: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  flex: 1;
  margin-left: 40px;
  min-height: 60px;

  @media (max-width: 600px) {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 20px;

  @media (min-width: 600px) {
    position: absolute;
    width: 240px;
    top: 196px;
    right: 0;
    margin-bottom: 0px;
  }

  .buttons-pane {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    @media (min-width: 600px) {
      padding-bottom: 20px;
    }
  }
`;

const ScrollView = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  @media (min-width: 600px) {
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #0b1918;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #001c1a;
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #002724;
    }
  }
`;
