import styled from '@emotion/styled';
import { IconButton } from '@mui/material';

export const Root = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  @media (max-width: 600px) {
    flex-wrap: nowrap;
    flex-direction: column-reverse;
  }
`;

export const RightRoot = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 8px 16px;
`;

export const HeaderButton = styled(IconButton)`
  width: 40px;
  height: 40px;
  font-size: 32px;
  margin-left: 16px;

  @media (max-width: 600px) {
    margin-left: 12px;
    width: 32px;
    height: 32px;
    font-size: 24px;
  }
`;
