import styled from '@emotion/styled';
import React from 'react';
import Button from './Button';

type Props = {
  icon: React.ReactNode;
  label: string;
  onClick?: React.MouseEventHandler;
  activated?: boolean;
  style?: React.CSSProperties;
  className?: string;
  disabled?: boolean;
  animation?: boolean;
};

type State = {
  isPressed?: boolean;
};

const initState = Object.freeze<State>({});

export default class IconButtonWithLabel extends React.Component<Props, State> {
  state = initState;

  _timeDown = 0;
  _t?: number;

  handleMouseDown = () => {
    this.setState({ isPressed: true });
    this._timeDown = Date.now();
    if (this._t) {
      window.clearTimeout(this._t);
      this._t = undefined;
    }

    window.addEventListener('mouseup', this.handleMouseUp);
  };

  handleMouseUp = () => {
    window.removeEventListener('mouseup', this.handleMouseUp);
    const remainning = 500 - (Date.now() - this._timeDown);
    if (remainning > 0) {
      this._t = window.setTimeout(() => {
        this._t = undefined;
        this.setState({ isPressed: false });
      }, remainning);
      return;
    }
    this.setState({ isPressed: false });
  };

  render(): React.ReactNode {
    return (
      <Root
        disabled={this.props.disabled}
        disableTouchRipple
        style={this.props.style}
        onClick={this.props.onClick}
        className={this.props.className}
        onMouseDown={this.props.animation ? this.handleMouseDown : undefined}
      >
        <StyledIcon
          animation={this.props.animation && this.state.isPressed}
          className="buttonLabel-icon"
          activated={this.props.activated}
        >
          {this.props.icon}
        </StyledIcon>
        <Label>{this.props.label}</Label>
      </Root>
    );
  }
}

type ActiveProps = { activated?: boolean };

const Root = styled(Button)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  :disabled {
    opacity: 0.7;
    .buttonLabel-icon {
      background-color: rgba(47, 96, 93, 0.5);
      color: #9c9c9c;
    }
  }
`;

const StyledIcon = styled.div<ActiveProps & { animation?: boolean }>`
  background-color: ${({ activated }) =>
    activated ? '#6BE5D7' : 'rgba(47, 96, 93, 0.5)'};
  color: ${({ activated }) => (activated ? '#001C1A' : '#ffffff')};
  width: 52px;
  height: 52px;
  font-size: 24px;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  display: flex;
  :hover {
    background-color: ${({ activated }) =>
      activated ? '#40dac8' : 'rgba(41, 98, 94, 0.5)'};
  }

  transform: rotate(0deg);
  ${({ animation }: { animation?: boolean }) =>
    animation
      ? `transition: transform 0.5s ease-in-out; transform: rotate(720deg);`
      : ''}
`;

const Label = styled.span`
  font-size: 12px;
  color: rgba(255, 255, 255, 0.7);
  font-weight: 500;
  text-align: center;
  margin-top: 8px;
  white-space: nowrap; ;
`;
