import styled from '@emotion/styled';
import { mdiCardsHeart, mdiCardsHeartOutline, mdiClose } from '@mdi/js';
import { CircularProgress, Dialog } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import medioApi from '../../../../../api/medio';
import Button from '../../../../../components/Button';
import {
  ChevronDownIcon,
  MdiIcon,
  PauseIcon,
  PlayIcon,
} from '../../../../../components/icons';
import MusicPlayer from '../../../../../controllers/MusicPlayer';
import { useIsMobile } from '../../../../../hooks/layout';
import { useCurrentMedio, usePlayerState } from '../../../../../hooks/player';
import useQuery from '../../../../../hooks/use-query';
import { Medio } from '../../../../../models/medio';
import { capacityFirst } from '../../../../../utils/string';
import { StyledIconButton } from '../../library/styled';

type Props = {
  open: boolean;
  onClose?: () => void;
};

export default function MedioDetail(props: Props) {
  const isMobile = useIsMobile();
  const { medio: id } = useQuery();
  const [detail, setDetail] = useState<Medio | undefined>();
  const { t } = useTranslation();
  const currentMedio = useCurrentMedio();
  const playerState = usePlayerState();

  const isPlaying =
    detail && currentMedio?.id === detail?.id && playerState === 'play';

  useEffect(() => {
    if (id && props.open) {
      medioApi.getMedio(+id).then(setDetail);
    } else {
      setDetail(undefined);
    }
  }, [id, props.open]);

  const PlayerIcon = isPlaying ? PauseIcon : PlayIcon;

  const metaDataEl = (
    <>
      <PropertyRow
        label={t('Content type')}
        text={capacityFirst(t(detail?.contentType ?? ''))}
      />
      <PropertyRow
        label={t('Duration')}
        text={
          detail?.durationRange
            ? `${detail?.durationRange} ${t('mins')}`
            : capacityFirst(t('unknown'))
        }
      />
      <PropertyRow
        label={t('Released')}
        text={capacityFirst(moment(detail?.releaseAt).format('MMMM YYYY'))}
      />
    </>
  );

  return (
    <Dialog
      open={props.open}
      onClose={props.onClose}
      fullScreen={isMobile}
      fullWidth
      PaperProps={{
        style: {
          maxWidth: 760,
          background: 'transparent',
          color: 'inherit',
          boxShadow: 'none',
          position: 'relative',
        },
      }}
    >
      <ClosePane>
        <Button
          sx={{ p: 1, minWidth: 0 }}
          color="inherit"
          onClick={props.onClose}
        >
          {isMobile ? <ChevronDownIcon /> : <MdiIcon d={mdiClose} />}
        </Button>
      </ClosePane>
      <Container>
        {detail ? (
          <>
            <BackgroundImage src={detail?.photoURL} />
            <ContentContainer>
              <Title>{detail?.title}</Title>
              <MetaContainer>
                <div className="buttons-pane">
                  <StyledIconButton
                    color="secondary"
                    size="small"
                    onClick={() => {
                      if (detail) {
                        const liked = !detail.liked;
                        medioApi
                          .postActivity(detail.id, {
                            like: liked,
                          })
                          .then(() => {
                            const d = {
                              ...detail!,
                              liked,
                            };
                            MusicPlayer.updateMedioDetail(d);
                            setDetail(d);
                          });
                      }
                    }}
                  >
                    <MdiIcon
                      d={detail?.liked ? mdiCardsHeart : mdiCardsHeartOutline}
                    />
                  </StyledIconButton>
                  <StyledIconButton
                    style={{ marginTop: 0, marginBottom: 0 }}
                    color="primary"
                    size="large"
                    disabled={MusicPlayer.state === 'loading'}
                    onClick={() => {
                      if (
                        MusicPlayer.state === 'play' &&
                        currentMedio?.id === detail?.id
                      ) {
                        // if player is playing this medio
                        MusicPlayer.pause();
                        return;
                      }
                      MusicPlayer.setMedio(detail);
                    }}
                  >
                    {playerState === 'loading' ? (
                      <CircularProgress size={24} />
                    ) : (
                      <PlayerIcon fontSize="inherit" />
                    )}
                  </StyledIconButton>
                </div>
                {!isMobile && metaDataEl}
              </MetaContainer>
              <ScrollView>
                {isMobile && metaDataEl}

                <Description>
                  <div style={{ padding: '20px 0' }}>{detail?.description}</div>
                </Description>
              </ScrollView>
            </ContentContainer>
          </>
        ) : (
          <LoadingRoot>
            <CircularProgress size={48} />
          </LoadingRoot>
        )}
      </Container>
    </Dialog>
  );
}

function PropertyRow(props: { label: string; text: string }) {
  return (
    <div className="property-row">
      <span className="property-label">{props.label}: </span>
      <span className="property-text">{props.text}</span>
    </div>
  );
}

const ClosePane = styled.div`
  display: flex;
  justify-content: flex-end;
  @media (max-width: 600px) {
    position: absolute;
    z-index: 10;
  }
`;

const Container = styled.div`
  /* padding: 16px; */

  background: #172b2a;
  overflow: hidden;
  box-shadow: 0 0 6px rgba(23, 43, 42, 0.2);
  position: relative;
  display: flex;
  flex: 1;
  flex-direction: column;
  .property-row {
    padding: 8px 20px;
    font-weight: 500;
    font-size: 16px;
    line-height: 150%;
    .property-label {
      color: rgba(255, 255, 255, 0.5);
    }
    .property-text {
      color: #ffffff;
    }
  }
  @media (min-width: 600px) {
    border-radius: 16px;
    /* height: 90vh;
    max-height: 600px; */
  }
`;

const BackgroundImage = styled.div<{ src?: string }>`
  width: 100%;
  height: 285px;
  background-image: url(${({ src }) => src});
  background-position: center;
  background-size: cover;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  :after {
    position: absolute;
    content: '';
    display: block;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: linear-gradient(180deg, rgba(23, 43, 42, 0) 50%, #172b2a 100%);
  }
`;

const ContentContainer = styled.div`
  position: relative;
  z-index: 1;
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 32px;
  line-height: 150%;
  padding-right: 260px;
  padding-left: 40px;
  margin-bottom: 20px;

  @media (max-width: 600px) {
    margin-bottom: 0px;
    padding-left: 20px;
    padding-right: 20px;
  }
`;

const Description = styled.div`
  font-weight: 500;
  font-size: 16px;
  line-height: 150%;
  color: rgba(255, 255, 255, 0.8);
  padding-right: 260px;
  padding-top: 20px;
  padding-bottom: 20px;
  margin-bottom: 20px;
  flex: 1;
  margin-left: 40px;
  min-height: 60px;

  @media (max-width: 600px) {
    margin-top: 20px;
    margin-right: 20px;
    margin-left: 20px;
    padding-right: 0;
    border-top: 1px solid rgba(255, 255, 255, 0.1);
  }
`;

const MetaContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 20px;
  margin-bottom: 20px;

  @media (min-width: 600px) {
    position: absolute;
    width: 240px;
    top: 196px;
    right: 0;
    margin-bottom: 0px;
  }

  .buttons-pane {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: flex-end;

    @media (min-width: 600px) {
      padding-bottom: 20px;
    }
  }
`;

const ScrollView = styled.div`
  display: flex;
  overflow: auto;
  flex-direction: column;
  @media (min-width: 600px) {
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #0b1918;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #001c1a;
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #002724;
    }
  }
`;

const LoadingRoot = styled.div`
  min-height: 250px;
  display: flex;
  justify-content: center;
  align-items: center;
`;
