import Enum from '../utils/enum';

export interface CollectionConfig {
  backgroundColor: {
    dark: string;
    light: string;
  };
  color?: {
    dark?: string;
    light?: string;
  };
  tabs: TabConfig[];
}

export interface CollectionData {
  id: number;
  title: string;
  photoURL: string;
  description: string;
  visible?: boolean;
}

export const FilterType = {
  Featured: 'featured',
  Classified: 'classified',
  Recent: 'recent',
  Live: 'live',
  Breathing: 'breathing',
  Favorite: 'favorite',
  New: 'new',
  Upcoming: 'upcoming',
  Recommended: 'recommended',
} as const;
export type FilterType = Enum<typeof FilterType>;

export const ContentType = {
  Meditation: 'meditation',
  Course: 'course',
  Series: 'series',
  Story: 'story',
  Song: 'song',
  Album: 'album',
  Playlist: 'playlist',
  Soundscape: 'soundscape',
} as const;
export type ContentType = Enum<typeof ContentType>;

export interface SectionConfig {
  key: string;
  label: string;
  target: SectionTaget;
  filterType: FilterType;
  contentTypes?: ContentType[];
  tags?: string[];
  excludeTags?: string[];
}

export const SectionTaget = {
  Tab: 'tab',
  List: 'list',
  None: 'none',
} as const;
export type SectionTaget = Enum<typeof SectionTaget>;

export const TabType = {
  Section: 'section',
  ListContent: 'all-content',
} as const;
export type TabType = Enum<typeof TabType>;

interface TabConfigData {
  key: string;
  title: string;
}

export interface TabSectionConfig extends TabConfigData {
  type: typeof TabType.Section;
  sections: SectionConfig[];
}
export interface TabListContentConfig extends TabConfigData {
  type: typeof TabType.ListContent;
  filterType: FilterType;
  contentTypes?: ContentType[];
  tags?: string[];
  excludeTags?: string[];
}

export type TabConfig = TabSectionConfig | TabListContentConfig;

export const DiscoverCollection = {
  Learning: 2,
  ReduceStress: 3,
  ImproveSleep: 1,
  Family: 6,
  IncreaseConcentration: 4,
  HealWounds: 5,
} as const;

export type DiscoverCollection = Enum<typeof DiscoverCollection>;

export const TabData = {
  All: { key: 'all', title: 'Tất cả' },
  Single: { key: 'single', title: 'Bài đơn' },
  Sound: { key: 'sound', title: 'Âm thanh' },
  Course: { key: 'course', title: 'Khoá học' },
  Live: { key: 'live', title: 'Live' },
  Story: { key: 'story', title: 'Kể chuyện' },
  Tip: { key: 'tips', title: 'Mẹo hay' },
} as const;

export const contentTypeConfig: {
  [key: string]: {
    label: string;
    bgColor?: string;
    color?: string;
  };
} = {
  meditation: {
    label: 'Bài thiền',
    bgColor: '#27AE60',
  },
  course: { label: 'Khoá học', bgColor: '#F08F39' },
  series: { label: 'Series', bgColor: 'orange' },
  story: { label: 'Kể chuyện', bgColor: '#7C13AD' },
  song: { label: 'Âm nhạc', bgColor: '#F34848' },
  album: { label: 'Album', bgColor: 'orange' },
  playlist: { label: 'Playlist', bgColor: 'orange' },
  soundscape: { label: 'Âm thanh', bgColor: '#1C86FF' },
};
