import styled from '@emotion/styled';
import { mdiCardsHeartOutline } from '@mdi/js';
import React from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import Button from '../../../../components/Button';
import { FeedbackIcon, MdiIcon } from '../../../../components/icons';
import useQuery from '../../../../hooks/use-query';
import { createSearchQuery } from '../../../../utils/query';

type Props = {};

export default function Footer(props: Props) {
  const navigate = useNavigate();
  const query = useQuery();
  return (
    <Root>
      {/* <FeedbackButton
        color="inherit"
        onClick={() => {
          navigate({
            search: createSearchQuery({
              ...query,
              feedback: true,
            }),
          });
        }}
        startIcon={<FeedbackIcon fontSize="inherit" color="inherit" />}
        endIcon={<MdiIcon d={mdiCardsHeartOutline} />}
      >
        <Trans>Feedback to Mindfully here</Trans>
      </FeedbackButton> */}
    </Root>
  );
}

const Root = styled.div`
  max-width: 256px;
  margin: auto;
  text-align: center;
`;

const FeedbackButton = styled(Button)`
  position: relative;
  ::after {
    border-bottom: 1px solid #fff;
    content: '';
    display: block;
    position: absolute;
    left: 8px;
    right: 8px;
    bottom: 1px;
  }
`;
