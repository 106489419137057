import { memo } from 'react';
import OptionButton from './OptionButton';
import ProfileButton from './ProfileButton';
import { RightRoot, Root } from './styled';
import Tabs from './Tabs';

type Props = {};

export default memo(function HeaderBar(props: Props) {
  return (
    <Root>
      <div className="expanded" />
      <Tabs />
      <RightRoot>
        <ProfileButton />
        <OptionButton />
      </RightRoot>
    </Root>
  );
});
