import { connect } from 'react-redux';
import { RootState, store } from '../redux';
import React, { useEffect } from 'react';
import { useSetLoginPopupOpen } from '../hooks/auth';

type PrivateProps = {
  active: boolean;
} & ReturnType<typeof mapStateToProps>;

// @ts-ignore
const Private: React.FC<PrivateProps> = ({ active, children, auth }) => {
  const setLoginPopupOpen = useSetLoginPopupOpen();
  useEffect(() => {
    if (active && !auth.firebaseAuthLoading) {
      const isAuth = auth.firebaseUser && auth.mindfullyAuth;
      if (!isAuth && !store.getState().auth.loginPopupOpen) {
        setLoginPopupOpen(true);
        return;
      }
    }
  }, [
    auth.firebaseAuthLoading,
    auth.firebaseUser,
    auth.mindfullyAuth,
    setLoginPopupOpen,
    active,
  ]);

  if (auth.firebaseAuthLoading) {
    // return (
    //   <div style={{ padding: 20, display: 'flex', justifyContent: 'center' }}>
    //     <CircularProgress size={32} />
    //   </div>
    // );
    return null;
  }
  return auth.firebaseUser && auth.mindfullyAuth ? children : null;
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});
export default connect(mapStateToProps)(Private);
