import EventEmitter from 'events';

export default class PlaybackController {
  private static _shared: PlaybackController;
  public static get shared() {
    if (!this._shared) {
      this._shared = new PlaybackController();
    }
    return this._shared;
  }
  public type?: 'medio' | 'wellness';

  private _emitters = {
    medio: new EventEmitter(),
    wellness: new EventEmitter(),
  };

  private get currentEmitter() {
    if (this.type) {
      return this._emitters[this.type];
    }
    return undefined;
  }

  public get wellnessEmitter() {
    return this._emitters.wellness;
  }

  public get playerEmitter() {
    return this._emitters.medio;
  }

  public setNowPlaying(type: 'medio' | 'wellness', metadata: MediaMetadata) {
    this.type = type;
    if (type === 'wellness') {
      if (navigator.mediaSession) {
        navigator.mediaSession.setActionHandler('play', (d) => {
          this.currentEmitter?.emit('play');
        });
        navigator.mediaSession.setActionHandler('pause', (d) => {
          this.currentEmitter?.emit('pause');
        });
      }
      navigator.mediaSession.metadata = metadata;

      navigator.mediaSession.setPositionState({
        duration: 0,
        playbackRate: 1,
        position: 0,
      });
      return;
    }
    navigator.mediaSession.setActionHandler('play', null);
    navigator.mediaSession.setActionHandler('pause', null);
    navigator.mediaSession.metadata = metadata;
  }
}
