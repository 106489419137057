import styled from '@emotion/styled';
import { mdiChevronLeft } from '@mdi/js';
import { CircularProgress, Dialog, IconButton, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React, { useState } from 'react';
import { Trans } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { MdiIcon } from '../../../../components/icons';
import useQuery from '../../../../hooks/use-query';

type Props = {};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function FeedbackPopup(props: Props) {
  const navigate = useNavigate();
  const query = useQuery();
  return (
    <Dialog
      open={!!query.feedback}
      fullScreen={true}
      TransitionComponent={Transition}
      fullWidth={true}
      PaperProps={{
        sx: {
          p: 0,
          background: '#172B2A',
          color: 'inherit',
        },
      }}
    >
      <Root>
        <TopContainer>
          <IconButton
            color="inherit"
            onClick={() => {
              navigate(-1);
            }}
          >
            <MdiIcon d={mdiChevronLeft} />
          </IconButton>
          <Title>
            <Trans>Feedback</Trans>
          </Title>
          <div style={{ width: 40 }} />
        </TopContainer>
        <FeedbackFrame />
      </Root>
    </Dialog>
  );
}

const FeedbackFrame = () => {
  const [loading, setLoading] = useState(true);

  return (
    <>
      {!!loading && (
        <CircularProgress
          sx={{ mt: 2, top: 60, position: 'absolute' }}
          size={24}
          color="inherit"
        />
      )}
      <IFrame
        src={process.env.REACT_APP_FEEDBACK_SRC}
        // onError={(e) => alert(e.currentTarget.e)}
        loading="lazy"
        title="feedback"
        onLoad={() => setLoading(false)}
      />
    </>
  );
};

const Root = styled.div`
  box-sizing: border-box;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const TopContainer = styled.div`
  width: 100%;
  max-width: 720px;
  padding: 8px 0;
  display: flex;
  align-items: center;
`;

const IFrame = styled.iframe`
  width: 100%;
  max-width: 720px;
  flex: 1;
  border: none;
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  flex: 1;
  text-align: center;
`;
