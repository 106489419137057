import Enum from '../../utils/enum';
import { typedAction } from '../typed-action';

export const AppActionEnum = {
  SET_APP_BAR_TITLE: 'app/SET_APP_BAR_TITLE',
  SET_ABOUT_US_OPEN: 'app/SET_ABOUT_US_OPEN',
  SET_LANGUAGE_OPEN: 'app/SET_LANGUAGE_OPEN',
  SET_LANGUAGE: 'app/SET_LANGUAGE',
  SET_GUIDES_OPEN: 'app/SET_GUIDES_OPEN',
  SET_BREADCRUMBS: 'app/SET_BREADCRUMBS',
  ADD_BREADCRUMB: 'app/ADD_BREADCRUMB',
  RESET: 'RESET',
} as const;

export type AppActionEnum = Enum<typeof AppActionEnum>;

function setAppBarTitle(title: string) {
  return typedAction(AppActionEnum.SET_APP_BAR_TITLE, { title });
}
function setLanguage(lang: string) {
  return typedAction(AppActionEnum.SET_LANGUAGE, lang);
}
function setAboutUsOpen(open: boolean) {
  return typedAction(AppActionEnum.SET_ABOUT_US_OPEN, open);
}
function setLanguageOpen(open: boolean) {
  return typedAction(AppActionEnum.SET_LANGUAGE_OPEN, open);
}
function setGuidesOpen(open: boolean) {
  return typedAction(AppActionEnum.SET_GUIDES_OPEN, open);
}

export type AppActionTypes =
  | ReturnType<typeof setAppBarTitle>
  | ReturnType<typeof setAboutUsOpen>
  | ReturnType<typeof setLanguageOpen>
  | ReturnType<typeof setGuidesOpen>
  | ReturnType<typeof setLanguage>
  | { type: typeof AppActionEnum.RESET };

export default {
  setAppBarTitle,
  setGuidesOpen,
  setLanguageOpen,
  setAboutUsOpen,
  setLanguage,
};
