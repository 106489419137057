import EventEmitter from 'events';
import medioApi from '../api/medio';
import { Medio } from '../models/medio';
import { store } from '../redux';
import { MeditationActions } from '../redux/meditation';
import PlaybackController from './PlaybackController';
import WellnessPlayer from './WellnessPlayer';

class MusicPlayer extends EventEmitter {
  private _player = new Audio();

  private _state: 'play' | 'pause' | 'loading' = 'pause';
  public get state() {
    return this._state;
  }

  private _medio?: Medio;

  public get medio() {
    return this._medio;
  }

  private _topicId?: number;
  public get topicId() {
    return this._topicId;
  }

  constructor() {
    super();
    this.setMaxListeners(0);
    const pauseListener = () => {
      if (this.state !== 'pause') {
        this._state = 'pause';
        this.emit('state-change');
      }
    };
    const playListener = () => {
      if (this.state !== 'play') {
        this._state = 'play';
        this.emit('state-change');
      }
    };
    const loadSourceListener = () => {
      if (this.state !== 'loading') {
        this._state = 'loading';
        this.emit('state-change');
      }
    };
    const onError = (error: any) => {
      if (error === 'source-load-failed') {
        this.emit('error', 'Media source error.');
        return;
      }
      console.error(error);
      // this.emit('error', error.toString());
    };
    this._player.addEventListener('play', playListener);
    this._player.addEventListener('pause', pauseListener);

    WellnessPlayer.instance.onplay = playListener;
    WellnessPlayer.instance.onpause = pauseListener;
    WellnessPlayer.instance.onLoadSource = loadSourceListener;
    WellnessPlayer.instance.onError = onError;
    this._player.addEventListener('error', (e) => {
      pauseListener();
      switch (this._player.error?.code) {
        case this._player.error?.MEDIA_ERR_ABORTED:
          this.emit('You aborted the media playback.');
          break;
        case this._player.error?.MEDIA_ERR_NETWORK:
          this.emit('A network error caused the media download to fail.');
          break;
        case this._player.error?.MEDIA_ERR_DECODE:
          this.emit(
            'The media playback was aborted due to a corruption problem or because the media used features your browser did not support.'
          );
          break;
        case this._player.error?.MEDIA_ERR_SRC_NOT_SUPPORTED:
          this.emit(
            'The media could not be loaded, either because the server or network failed or because the format is not supported.'
          );
          break;
        default:
          this.emit('An unknown media error occurred.');
      }
    });
    this._player.addEventListener('playing', () => {
      this.emit('playing');
      // Timer.resume();
    });
  }

  setMedio(medio?: Medio) {
    this._player.loop = false;

    if (medio?.id === this.medio?.id && this._player.src) {
      if (medio && this.state !== 'play') {
        WellnessPlayer.instance.pause();
        this.play();
      }
      return;
    }
    // this._player.pause();
    // this._player.currentTime = 0;
    if (medio?.id !== this.medio?.id) {
      this._medio = medio;
      this.emit('medio-change', medio);
    }

    if (!medio) {
      this._removeSrc();

      return;
    }

    return medioApi
      .getMedio(medio.id)
      .then((res) => {
        if (res.topicId) {
          store.dispatch(MeditationActions.setCurrentTopic(res.topicId));
        }
        const session = res.medioSessions?.[0];
        if (!session) {
          this.pause();
          this._state = 'pause';
          this.emit('state-change');
          this._removeSrc();
          this.emit('error', 'Media source not found');
          return;
        }
        const master = session.audios.find((f) => f.type === 'master');
        if (master) {
          this._player.src = master.audioURL;
        } else {
          this._player.src = session.audioURL;
        }
        WellnessPlayer.instance.pause();
        this.play();
      })
      .catch(console.warn);
  }

  updateMedioDetail(medio: Medio) {
    if (this.medio?.id === medio.id) {
      if (medio !== this.medio) {
        this._medio = medio;
        this.emit('medio-change', medio);
      }
    }
  }

  setTopic(topicId?: number) {
    this._topicId = topicId;
    if (typeof topicId === 'number') {
      this._player.pause();
      WellnessPlayer.instance.playTopic(topicId);
    }
  }

  playTopicMusic(topicId: number) {
    this.setMedio();
    this.setTopic(topicId);
  }

  refreshTopicMusic() {
    if (this._medio) {
      return;
    }

    if (this.topicId) {
      this._removeSrc();

      this.setTopic(this.topicId);
    }
  }

  play() {
    this._player.volume = 1;
    if (this._player.src) {
      this._player.play();
      PlaybackController.shared.setNowPlaying(
        'medio',
        new MediaMetadata({
          album: this.medio?.contentType,
          artist: 'mindfully',
          artwork: [
            {
              src:
                this.medio?.photoURL ??
                `${process.env.PUBLIC_URL}/assets/images/logo.png`,
            },
          ],
          title: this.medio?.title,
        })
      );
      return;
    }
    if (typeof this._topicId === 'number') {
      if (!WellnessPlayer.instance.tracks?.length) {
        WellnessPlayer.instance.playTopic(this._topicId!);
        return;
      }
      WellnessPlayer.instance.play();
    }
  }

  setVolume(v: number) {
    this._player.volume = v;
  }

  pause() {
    this._player.pause();
    WellnessPlayer.instance.pause();
  }

  private _removeSrc() {
    this._player.src = '';
    this._player.removeAttribute('src');
  }
}

export default new MusicPlayer();
