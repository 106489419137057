import styled from '@emotion/styled';
import { Dialog } from '@mui/material';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import Button from '../../../../components/Button';
import Select from '../../../../components/Select';
import { useLanguage } from '../../../../hooks/app';
import { RootState } from '../../../../redux';
import { AppActions } from '../../../../redux/app';

type Props = {};

export default function LanguagePopup(props: Props) {
  const lang = useLanguage();
  const dispatch = useDispatch();
  const state = useSelector((state: RootState) => ({
    languageOpen: state.app.languageOpen,
    lang: state.app.lang,
  }));

  return (
    <Dialog
      PaperProps={{
        style: {
          borderRadius: 16,
          padding: 16,
          backgroundColor: '#172B2A',
          fontFamily: 'inherit',
          color: 'inherit',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        },
      }}
      open={!!state.languageOpen}
      onClose={() => {
        dispatch(AppActions.setLanguageOpen(false));
      }}
    >
      <PopupTitle>
        <Trans>Choose Language</Trans>
      </PopupTitle>

      <Select
        value={lang}
        options={[
          {
            value: 'en',
            label: 'English',
          },
          {
            value: 'vi',
            label: 'Tiếng Việt',
          },
        ]}
        onChange={(option) => {
          if (option) {
            dispatch(AppActions.setLanguage(option.value.toString()));
          }
        }}
      />

      <PopupActions>
        <ActionButton
          onClick={() => {
            dispatch(AppActions.setLanguageOpen(false));
          }}
          color="secondary"
        >
          <Trans>Close</Trans>
        </ActionButton>
      </PopupActions>
    </Dialog>
  );
}

const PopupTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  padding: 8px;
  min-width: 180px;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
    padding: 4px 16px 16px 16px;
  }
  @media (max-height: 600px) {
    padding-bottom: 16px;
  }
`;

const PopupActions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 380px;
  margin: 20px auto 8px auto;
  @media (max-height: 600px) {
    margin-top: 20px;
  }
`;

const ActionButton = styled(Button)`
  max-width: 170px;
  flex: 1;
  font-weight: 500;
  margin: 0 8px;
  &.MuiButton-textSecondary {
    background-color: rgba(255, 255, 255, 0.2);
    color: inherit;
  }
  &.MuiButton-textPrimary {
    background-color: rgba(255, 255, 255, 1);
    color: var(--monochrome-6);
  }
`;
