import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../redux';
import { AuthActions } from '../redux/auth';

export function useSetLoginPopupOpen() {
  const dispatch = useDispatch();
  return useCallback(
    (open: boolean) => {
      dispatch(AuthActions.setLoginPopupOpen(open));
    },
    [dispatch]
  );
}

export function useLoginPopupOpen() {
  return useSelector((state: RootState) => state.auth.loginPopupOpen);
}
