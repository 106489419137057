import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { authReducer } from './auth';
import { userReducer } from './user';
import { appReducer } from './app';
import { meditationReducer } from './meditation';

const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  app: appReducer,
  meditation: meditationReducer,
});

export const persistedReducer = persistReducer(
  {
    key: 'root',
    storage,
    whitelist: [],
  },
  rootReducer
);

export type RootState = ReturnType<typeof rootReducer>;
