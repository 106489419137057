import styled from '@emotion/styled';
import { Chip } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { tabConfigs } from '../../../../../app-config';
import useQuery from '../../../../../hooks/use-query';
import { createSearchQuery } from '../../../../../utils/query';
import { capacityFirst } from '../../../../../utils/string';

type Props = {};

export default function Tabs(props: Props) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const query = useQuery();
  const currentTab = query.tab || 'all';
  return (
    <Root>
      <Tab
        variant="filled"
        color={currentTab === 'all' ? 'primary' : undefined}
        label={capacityFirst(t('all'))}
        onClick={() => {
          navigate({
            search: createSearchQuery({
              ...query,
              tab: 'all',
            }),
          });
        }}
      />
      {tabConfigs.map((tab) => (
        <Tab
          variant="filled"
          color={tab.key === currentTab ? 'primary' : undefined}
          key={tab.key}
          label={capacityFirst(t(tab.title))}
          onClick={() => {
            navigate({
              search: createSearchQuery({
                ...query,
                tab: tab.key,
              }),
            });
          }}
        />
      ))}
    </Root>
  );
}

const Root = styled.div`
  height: 58px;
  background-color: #001c1a;
  overflow: scroll;
  display: flex;
  align-items: center;
  padding: 0 20px;

  @media (min-width: 681px) {
    // 2 items
    padding: 0 calc(50% - 322px);
  }
  @media (min-width: 1000px) {
    // 3 items
    padding: 0 calc(50% - 480px);
  }
  @media (min-width: 1400px) {
    // 4 items
    padding: 0 calc(50% - 638px);
  }
  @media (min-width: 1800px) {
    // 5 items
    padding: 0 calc(50% - 796px);
  }

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Tab = styled(Chip)`
  font-family: inherit;
  padding: 6px 16px;
  font-size: 12px;
  height: 30px;
  margin: 0 4px;
  font-weight: 700;
  &.MuiChip-filled {
    color: #14ada6;
    background-color: rgba(0, 194, 188, 0.1);
    &.MuiChip-colorPrimary {
      color: #172b2a;
      background-color: #14ada6;
    }
  }
`;
