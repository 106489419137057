import { mdiClose } from '@mdi/js';
import { Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import Button from '../../../../components/Button';
import { MdiIcon } from '../../../../components/icons';
import Player from './components/Player';
import Tabs from './components/Tabs';
import { Container, Main, PopupAction, Root, Header } from './styled';
import { tabConfigs } from '../../../../app-config';
import AllTabContent from './components/AllTabContent';
import TabContent from './components/TabContent';

type Props = {
  onClose?: () => void;
  open: boolean;
  onMedioInfo?: () => void;
};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function LibraryPopup(props: Props) {
  return (
    <Root
      open={props.open}
      keepMounted
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        style: {
          color: 'inherit',
          position: 'relative',
          background: 'transparent',
          overflow: 'hidden',
        },
      }}
    >
      <PopupAction>
        <Button color="inherit" onClick={props.onClose}>
          <MdiIcon d={mdiClose} />
        </Button>
      </PopupAction>
      <Main>
        <Header>
          <Player onMedioInfo={props.onMedioInfo} />
          <Tabs />
        </Header>
        <Container
          onScroll={(e) => {
            console.log(e.currentTarget.scrollTop);
          }}
        >
          <AllTabContent />
          {tabConfigs.map((tab) => (
            <TabContent tab={tab} />
          ))}
        </Container>
      </Main>
    </Root>
  );
}
