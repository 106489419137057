import EventEmitter from 'events';
import { store } from '../redux';
import MusicPlayer from './MusicPlayer';

class Timer extends EventEmitter {
  private _running = false;
  public get running() {
    return this._running;
  }
  private _interval = 100; // milliseconds
  private _remaining = 0; // milliseconds
  public get remaining() {
    return this._remaining;
  }
  private _t?: number;
  constructor() {
    super();
    this._tick = this._tick.bind(this);
  }

  start(min: number, delay: number = 2000) {
    if (this._running) {
      throw new Error('Timer is running.');
    }
    this._remaining = min * 60 * 1000 + delay; // min to milliseconds
    if (this._t) {
      window.clearInterval(this._t);
    }
    if (this._remaining <= 0) {
      return;
    }
    this._t = window.setInterval(this._tick, this._interval);
    this._running = true;
    this.emit('started');
  }

  pause() {
    if (this._t) {
      window.clearInterval(this._t);
    }
    this._running = false;
    this._t = undefined;
    this.emit('paused');
  }

  stop() {
    if (this._t) {
      window.clearInterval(this._t);
    }
    this._t = undefined;
    this._running = false;
    this._remaining = 0;
    this.emit('stopped');
  }

  resume() {
    if (this._running) {
      return;
    }
    if (this._t) {
      window.clearInterval(this._t);
    }
    if (this._remaining <= 0) {
      return;
    }
    this._t = window.setInterval(this._tick, this._interval);
    this._running = true;
    this.emit('resumed');
  }

  private _tick() {
    const next = this._remaining - this._interval;
    this._remaining = Math.max(next, 0);
    this.emit('tick', this._remaining);
    if (this._remaining < 5000) {
      MusicPlayer.setVolume(this._remaining / 5000);
    }
    if (this._remaining <= 0) {
      this.emit('done');
      this.stop();
      if (store.getState().meditation.timerBell) {
        const player = new Audio(
          `${process.env.PUBLIC_URL}/assets/audios/ending-mindfully.mp3`
        );
        player.play();
      }
    }
  }
}

export default new Timer();
