import styled from '@emotion/styled';
import {
  mdiCardsHeart,
  mdiCardsHeartOutline,
  mdiInformationOutline,
} from '@mdi/js';
import { keyframes } from '@mui/material';
import React, { memo } from 'react';
import { useTranslation } from 'react-i18next';
import { MdiIcon, PauseIcon, PlayIcon } from '../../../../../components/icons';
import Image from '../../../../../components/Image';
import { useCurrentMedio, usePlayerState } from '../../../../../hooks/player';
import { Medio } from '../../../../../models/medio';
import MusicPlayer from '../../../../../controllers/MusicPlayer';
import { StyledIconButton } from '../styled';
import medioApi from '../../../../../api/medio';
import {
  useCurrentTopic,
  useSetTopicDetail,
} from '../../../../../hooks/meditation';

type Props = {
  medio?: Medio;
  onMedioInfo?: () => void;
};
export default memo(function Player(props: Props) {
  const { t } = useTranslation();
  const playerState = usePlayerState();

  const setTopicDetail = useSetTopicDetail();
  const currentMedio = useCurrentMedio();
  const currentTopic = useCurrentTopic();

  const PlayerStateIcon = playerState === 'play' ? PauseIcon : PlayIcon;

  return (
    <Root visible={true}>
      <BgImage src={currentMedio?.photoURL ?? currentTopic?.thumbnailUrl} />
      <BlurBackdrop>
        <Container>
          <CoverImageContainer>
            <CoverImage
              src={currentMedio?.photoURL ?? currentTopic?.thumbnailUrl}
            />
          </CoverImageContainer>
          <ContentContainer>
            <ContentRow>
              <ContentTypeText>
                {t(currentMedio?.contentType ?? 'Music only')}
              </ContentTypeText>
            </ContentRow>
            <ContentRow>
              <TitleText>{currentMedio?.title ?? currentTopic?.name}</TitleText>
            </ContentRow>
            <ContentRow style={{ margin: '8px 0' }}>
              <StyledIconButton
                color="secondary"
                size="small"
                disabled={!currentMedio}
                onClick={() => {
                  if (currentMedio) {
                    const liked = !currentMedio.liked;
                    medioApi
                      .postActivity(currentMedio.id, {
                        like: liked,
                      })
                      .then(() => {
                        const detail = {
                          ...currentMedio!,
                          liked,
                        };
                        MusicPlayer.updateMedioDetail(detail);
                      });
                  }
                }}
              >
                <MdiIcon
                  d={currentMedio?.liked ? mdiCardsHeart : mdiCardsHeartOutline}
                  fontSize="inherit"
                />
              </StyledIconButton>

              <StyledIconButton
                color="primary"
                size="large"
                onClick={() => {
                  if (MusicPlayer.state === 'pause') {
                    MusicPlayer.play();
                    return;
                  }
                  MusicPlayer.pause();
                }}
              >
                <PlayerStateIcon fontSize="inherit" />
              </StyledIconButton>
              <StyledIconButton
                color="secondary"
                size="small"
                onClick={() => {
                  if (currentMedio) {
                    props.onMedioInfo?.();
                    return;
                  }
                  if (currentTopic) {
                    setTopicDetail(currentTopic);
                  }
                }}
              >
                <MdiIcon d={mdiInformationOutline} fontSize="inherit" />
              </StyledIconButton>
            </ContentRow>
          </ContentContainer>
        </Container>
      </BlurBackdrop>
    </Root>
  );
});

const inEffect = keyframes`
  0%{
    height: 0;
  }
  100%{
    height: 222px;
  }
`;
const outEffect = keyframes`
  0%{
    height: 222px;
  }
  100%{
    height: 0;
  }
`;

const Root = styled.div<{ visible: boolean }>`
  position: relative;
  overflow: hidden;
  height: 222px;
  animation: ${({ visible }) => (visible ? inEffect : outEffect)} ease-in-out
    0.3s forwards;
`;

const BlurBackdrop = styled.div`
  display: block;
  position: absolute;
  height: 222px;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  background-color: rgba(47, 96, 93, 0.7);
  @media (min-width: 600px) {
    backdrop-filter: blur(32px);
  }
`;

const BgImage = styled(Image)`
  position: absolute;
  left: 0;
  top: 0;
  z-index: 0;
  width: 100%;
  height: 222px;
  object-fit: cover;
`;

const Container = styled.div`
  box-sizing: border-box;
  margin: auto;
  width: 100%;
  height: 222px;
  max-width: 800px;
  display: flex;
  padding: 16px;
  z-index: 10;
`;

const CoverImageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 20px;
  @media (min-width: 700px) {
    flex: 1;
  }
  @media (max-width: 600px) {
    display: none;
  }
`;

const CoverImage = styled(Image)`
  border-radius: 16px;
  width: 230px;
  height: 155px;
  object-fit: cover;
`;

const ContentContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ContentRow = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const ContentTypeText = styled.span`
  text-transform: uppercase;
  font-size: 12px;
`;

const TitleText = styled.span`
  font-weight: 700;
  font-size: 20px;
  line-height: 150%;
`;
