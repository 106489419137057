import styled from '@emotion/styled';
import { Collapse } from '@mui/material';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import MusicPlayer from '../../../controllers/MusicPlayer';
import Timer from '../../../controllers/Timer';
import { capacityFirst } from '../../../utils/string';

type Props = {};

export default function TimerCounter(props: Props) {
  const [remaining, setRemaining] = useState(Timer.remaining);
  const { t } = useTranslation();
  const ref = useRef<number | undefined>();
  const [state, setState] = useState<{
    on?: boolean;
    off?: boolean;
    counter?: boolean;
  }>({});
  // const isTimer
  useEffect(() => {
    const doneListener = () => {
      MusicPlayer.pause();
    };
    const startedListener = () => {
      setState({
        on: true,
      });
      if (ref.current) {
        window.clearTimeout(ref.current);
      }
      ref.current = window.setTimeout(() => {
        ref.current = undefined;
        setState({
          on: false,
          counter: true,
        });
      }, 2000);
    };
    const stoppedListener = () => {
      setState({
        off: true,
      });
      if (ref.current) {
        window.clearTimeout(ref.current);
      }
      ref.current = window.setTimeout(() => {
        ref.current = undefined;
        setState({});
      }, 2000);
    };

    Timer.on('tick', setRemaining);
    Timer.on('done', doneListener);
    Timer.on('started', startedListener);
    Timer.on('stopped', stoppedListener);

    return () => {
      Timer.off('tick', setRemaining);
      Timer.off('done', doneListener);
      Timer.off('started', startedListener);
      Timer.off('stopped', stoppedListener);
    };
  }, []);
  return (
    <Root>
      <AlertRoot timeout={300} in={state.on}>
        <Trans>prefix-timer-on</Trans>{' '}
        <span className="onText">
          <Trans>on</Trans>
        </span>
      </AlertRoot>
      <AlertRoot timeout={300} in={state.off}>
        <Trans>prefix-timer-off</Trans>{' '}
        <span className="offText">
          <Trans>off</Trans>
        </span>
      </AlertRoot>
      <AlertRoot timeout={300} in={state.counter}>
        {capacityFirst(t('auto stop music after'))}{' '}
        <span className="counterText">{formatDuration(remaining)}</span>
      </AlertRoot>
    </Root>
  );
}

function formatDuration(ms: number) {
  const d = moment.duration(ms, 'ms');
  if (d.hours()) {
    return `${d.hours()}:${d.minutes().toString().padStart(2, '0')}:${d
      .seconds()
      .toString()
      .padStart(2, '0')}`;
  }
  return `${d.minutes().toString().padStart(2, '0')}:${d
    .seconds()
    .toString()
    .padStart(2, '0')}`;
}

const Root = styled.div`
  position: relative;
  margin-bottom: 16px;
`;

const AlertRoot = styled(Collapse)`
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  font-size: 16px;
  font-weight: 500;

  .onText {
    color: seagreen;
    font-weight: 700;
    text-transform: uppercase;
  }
  .offText {
    color: #c20303;
    font-weight: 700;
    text-transform: uppercase;
  }
  .counterText {
    font-weight: 700;
    text-transform: uppercase;
  }
`;
