import { IRawMetadata } from '../models/metadata';
import { Topic } from '../models/topic';
import { request } from './base';

const getTopic = () => {
  return request<Topic[]>({
    url: '/music-therapy/topics',
    method: 'GET',
  });
};

const getMetadataFromTopic = (topicId: number) => {
  return request<{ clipEvents: IRawMetadata[] }>({
    url: `/music-therapy/topics/${topicId}`,
    method: 'GET',
  });
  // return new Promise<IRawMetadata[]>((resolve, reject) => {
  //   setTimeout(() => {
  //     resolve(mocks);
  //   }, 200);
  // });
};

export const functionalMusicApis = {
  getTopic,
  getMetadataFromTopic,
};
