import styled from '@emotion/styled';

export const Root = styled.div`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  background: linear-gradient(229.28deg, #1a4240 13.38%, #122221 85.98%);
`;

export const CurrentMeditationContainer = styled.div`
  text-align: center;
  margin-top: 16px;
`;

export const TopicDescription = styled.div`
  opacity: 0.5;
  max-width: 275px;
  margin: auto;
  font-size: 16px;
`;

export const ContentTypeRow = styled.div`
  text-transform: uppercase;
  opacity: 0.5;
`;

export const MeditationTitle = styled.div`
  font-size: 20px;
  font-weight: 600;
  mask-image: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.1) 0%,
    rgba(0, 0, 0, 0.7) 25%,
    rgba(0, 0, 0, 0.7) 75%,
    rgba(0, 0, 0, 0.1) 100%
  );
  max-width: 560px;
  margin: 16px auto;
`;

export const ControlPaneContainer = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  min-width: 320px;
  max-width: 480px;
  margin: auto;
  width: 100%;
`;

export const FooterContainer = styled.div`
  padding: 32px 16px;
`;
