import { Dispatch } from 'redux';
import { functionalMusicApis } from '../../api/functional-music';
import { ISourceMetadata } from '../../models/metadata';
import { Topic } from '../../models/topic';
import Enum from '../../utils/enum';
import { typedAction } from '../typed-action';

export const MeditationActionEnum = {
  SET_TOPIC_DETAIL: 'meditation/SET_TOPIC_DETAIL',
  SET_CURRENT_TOPIC: 'meditation/SET_CURRENT_TOPIC',
  SET_TOPICS: 'meditation/SET_TOPICS',
  SET_TIMER_BELL: 'meditation/SET_TIMER_BELL',
  CACHE_METADATA: 'meditation/CACHE_METADATA',
  RESET: 'RESET',
} as const;

export type MeditationActionEnum = Enum<typeof MeditationActionEnum>;

function setTopicDetail(topic?: Topic) {
  return typedAction(MeditationActionEnum.SET_TOPIC_DETAIL, topic);
}

function setCurrentTopic(topicId: number) {
  return typedAction(MeditationActionEnum.SET_CURRENT_TOPIC, topicId);
}

function setTopics(topics: Topic[]) {
  return typedAction(MeditationActionEnum.SET_TOPICS, topics);
}

function setTimerBell(bell: boolean) {
  return typedAction(MeditationActionEnum.SET_TIMER_BELL, bell);
}

function cacheMetadata(topicId: number, metadata: ISourceMetadata[]) {
  return typedAction(MeditationActionEnum.CACHE_METADATA, {
    topicId,
    metadata,
  });
}

export type MeditationActionTypes =
  | ReturnType<typeof setTimerBell>
  | ReturnType<typeof setTopicDetail>
  | ReturnType<typeof setTopics>
  | ReturnType<typeof cacheMetadata>
  | ReturnType<typeof setCurrentTopic>
  | { type: typeof MeditationActionEnum.RESET };

function fetchTopics() {
  return (dispatch: Dispatch) => {
    return functionalMusicApis.getTopic().then((res) => {
      dispatch(setTopics(res));
    });
  };
}

export default {
  setCurrentTopic,
  fetchTopics,
  setTimerBell,
  setTopicDetail,
  cacheMetadata,
};
