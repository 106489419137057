import { Medio, MedioRatingRecord } from '../models/medio';
import {
  authRequest,
  CancelOption,
  ListPaginationResponse,
  QueryType,
} from './base';

const postRatingMedio = (medioSessionId: number, ratingIndex: number) => {
  return authRequest({
    url: '/medio-ratings',
    method: 'POST',
    body: {
      medioSessionId,
      ratingIndex,
    },
  });
};

const getRatingMedio = (medioSessionId: number) => {
  return authRequest<MedioRatingRecord>({
    url: '/medio-ratings',
    method: 'GET',
    query: {
      medioSessionId,
    },
  });
};

const getRelatedMedios = (medioId: number) => {
  return authRequest<Medio[]>({
    url: `/medios/${medioId}/related-medios`,
    method: 'GET',
  });
};

const getMedio = (medioId: number) => {
  return authRequest<Medio>({
    url: `/medios/${medioId}`,
    method: 'GET',
  });
};

const getMedioList = (query?: QueryType, cancelable?: CancelOption) => {
  return authRequest<ListPaginationResponse<Medio>>(
    {
      url: `/medios`,
      method: 'GET',
      query,
    },
    cancelable
  );
};

const postActivity = (
  medioId: number | string,
  data: { like?: boolean; play?: boolean }
) => {
  return authRequest<Medio[]>({
    url: `/medios/${medioId}/activities`,
    method: 'POST',
    body: data,
  });
};

const medioApi = {
  postRatingMedio,
  getRatingMedio,
  getMedio,
  getRelatedMedios,
  getMedioList,
  postActivity,
};

export default medioApi;
