import styled from '@emotion/styled';
import { mdiEmail } from '@mdi/js';
import { CircularProgress, InputAdornment } from '@mui/material';
import { getAuth, sendSignInLinkToEmail } from 'firebase/auth';
import { FormEventHandler, useState } from 'react';
import { Trans } from 'react-i18next';
import { StyledFormHelperText } from '../../components/FormItem';
import { MdiIcon } from '../../components/icons';
import storage from '../../helper/storage';
import { FormErrorObj } from '../../models/form';
import { Form, LoginButton, TextField } from './styled';

type Props = { onBack?: () => void };

export default function EmailSentMessage(props: Props) {
  const [errors, setErrors] = useState<FormErrorObj>({});
  const [isLoading, setIsLoading] = useState(false);
  const [emailForLogin, setEmailForLogin] = useState('');

  const verifyData = (
    data: Partial<{
      email: string;
      password: string;
    }>
  ) => {
    const errors: FormErrorObj = {};
    if (!data.email) {
      errors.email = 'Email is required.';
    } else if (
      // eslint-disable-next-line no-useless-escape
      !/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(data.email)
    ) {
      errors.email = 'Invalid email address.';
    }
    if (Object.keys(errors).length) {
      return errors;
    }
    return;
  };

  function clearError(field: string) {
    const newErrors = { ...errors };
    delete newErrors[field];
    setErrors(newErrors);
  }

  const handleFormSubmit: FormEventHandler<HTMLFormElement> = (e) => {
    e.preventDefault();

    const email = (e.currentTarget['email'] as HTMLInputElement)?.value ?? '';
    const password =
      (e.currentTarget['password'] as HTMLInputElement)?.value ?? '';

    const errors = verifyData({ email, password });
    if (errors) {
      setErrors(errors);
      return;
    }

    setIsLoading(true);
    const auth = getAuth();
    sendSignInLinkToEmail(auth, email, {
      url: window.location.href,
      handleCodeInApp: true,
    })
      .then(() => {
        storage.setLoginWithEmail(email);
        setEmailForLogin(email);
      })
      // .then((userCredential) =>
      //   loginWithUserCredential(userCredential, SocialProviderEnum.EMAIL)
      // )
      // .catch((error) => {
      //   const errorCode = error.code;
      //   const errorMessage = error.message;
      //   console.log(errorCode, errorMessage);

      //   if (
      //     errorCode === 'auth/wrong-password' ||
      //     errorCode === 'auth/invalid-email'
      //   ) {
      //     setErrors({ all: 'Invalid username or password' });
      //   }
      // })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return emailForLogin ? (
    <Root>
      <Image
        src={`${process.env.PUBLIC_URL}/assets/images/socials/email.png`}
      />
      <div>
        <Trans>We sent an email to</Trans> <b>{emailForLogin}</b>.
      </div>
      <div className="mv-8">
        <Trans>Click the link in the email to log in</Trans>{' '}
        <strong>Wellness Music</strong>.
      </div>
    </Root>
  ) : (
    <Form onSubmit={handleFormSubmit}>
      <TextTitle>
        <Trans>Enter your email to continue</Trans>
      </TextTitle>
      <TextField
        InputProps={{
          sx: {
            minHeight: 40,
          },
          startAdornment: (
            <InputAdornment position="start">
              <MdiIcon d={mdiEmail} color="inherit" />
            </InputAdornment>
          ),
        }}
        name="email"
        placeholder="Email"
        margin="dense"
        size="small"
        error={!!errors.email}
        helperText={errors.email}
        onChange={() => {
          if (errors.email) {
            clearError('email');
          }
        }}
      />
      {!!errors.all && (
        <StyledFormHelperText
          sx={{
            textAlign: 'center',
            marginTop: 3,
            color: '#ba2121',
          }}
          error={!!errors.all}
        >
          {errors.all}
        </StyledFormHelperText>
      )}

      <LoginButton
        variant="contained"
        type="submit"
        sx={{
          marginTop: 2,
          textTransform: 'none',
        }}
        disabled={isLoading}
      >
        {isLoading ? (
          <CircularProgress size={24} color="inherit" />
        ) : (
          <Trans>Continue</Trans>
        )}
      </LoginButton>

      <div style={{ marginTop: 16 }}>
        <TextButton onClick={props.onBack}>
          <Trans>Continue with</Trans> Google <Trans>or</Trans> Facebook
        </TextButton>
      </div>
    </Form>
  );
}

const Root = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  padding-bottom: 16px;
`;

const Image = styled.img`
  width: 68px;
  height: 68px;
  margin: 0 auto 16px auto;
`;

const TextButton = styled.span`
  min-height: 0;
  padding: 0 4px;
  font-weight: 500;
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

const TextTitle = styled.div`
  margin-bottom: 8px;
  font-weight: 500;
  font-size: 16px;
`;
