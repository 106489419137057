import { Topic } from '../../models/topic';
import { MeditationActionEnum, MeditationActionTypes } from './action';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { ISourceMetadata } from '../../models/metadata';

type HomeState = {
  topics: Topic[];
  currentTopic?: number;
  topicDetail?: Topic;
  timerBell?: boolean;
  cached: Record<number, ISourceMetadata[][]>;
};

const initState = Object.freeze<HomeState>({
  topics: [],
  currentTopic: 1,
  cached: {},
});

function reducer(state = initState, action: MeditationActionTypes): HomeState {
  switch (action.type) {
    case MeditationActionEnum.SET_CURRENT_TOPIC: {
      return {
        ...state,
        currentTopic: action.payload,
      };
    }
    case MeditationActionEnum.SET_TOPIC_DETAIL: {
      return {
        ...state,
        topicDetail: action.payload,
      };
    }
    case MeditationActionEnum.SET_TOPICS: {
      return {
        ...state,
        topics: action.payload,
      };
    }
    case MeditationActionEnum.SET_TIMER_BELL: {
      return {
        ...state,
        timerBell: action.payload,
      };
    }
    case MeditationActionEnum.CACHE_METADATA: {
      const cached = state.cached[action.payload.topicId] ?? [];
      const nextArr =
        cached.length >= 5
          ? [...cached.slice(1), action.payload.metadata]
          : [...cached, action.payload.metadata];
      return {
        ...state,
        cached: {
          ...state.cached,
          [action.payload.topicId]: nextArr,
        },
      };
    }
    default: {
      return state;
    }
  }
}

export default persistReducer(
  {
    storage,
    key: 'meditation',
  },
  reducer
);
