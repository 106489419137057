import { useEffect, useState } from 'react';
import MusicPlayer from '../controllers/MusicPlayer';

export function usePlayerState() {
  const [state, setState] = useState(MusicPlayer.state);

  useEffect(() => {
    const listener = () => {
      setState(MusicPlayer.state);
    };
    MusicPlayer.on('state-change', listener);
    return () => {
      MusicPlayer.off('state-change', listener);
    };
  }, []);

  return state;
}

export function useCurrentMedio() {
  const [currentMedio, setCurrentMedio] = useState(MusicPlayer.medio);

  useEffect(() => {
    const listener = () => {
      setCurrentMedio(MusicPlayer.medio);
    };
    MusicPlayer.on('medio-change', listener);
    return () => {
      MusicPlayer.off('medio-change', listener);
    };
  }, []);
  return currentMedio;
}
