import styled from '@emotion/styled';
import { Collapse } from '@mui/material';
import React, { memo, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import medioApi from '../../../../../api/medio';
import Button from '../../../../../components/Button';
import { RightArrowIcon } from '../../../../../components/icons';
import useQuery from '../../../../../hooks/use-query';
import { ContentType, FilterType } from '../../../../../models/collection';
import { Medio } from '../../../../../models/medio';
import { createSearchQuery } from '../../../../../utils/query';
import { capacityFirst } from '../../../../../utils/string';
import ContentItem from './ContentItem';
import FeaturedItem from './FeaturedItem';

type Props = {
  title: string;
  featured?: boolean;
  tags?: string[];
  contentTypes?: ContentType[];
  collectionId?: number;
  target?: string;
};

export default memo(function Session(props: Props) {
  const navigate = useNavigate();
  const query = useQuery();
  const ItemComponent = props.featured ? FeaturedItem : ContentItem;
  // const [hover, setHover] = useState(false);
  const { t } = useTranslation();
  const [list, setList] = useState<Medio[]>([]);

  useEffect(() => {
    medioApi
      .getMedioList({
        filterType: props.featured
          ? FilterType.Featured
          : FilterType.Classified,
        tagNames: props.tags,
        contentTypes: props.contentTypes,
        collectionId: props.collectionId,
        pgLimit: 5,
      })
      .then((res) => {
        setList(res.items);
      });
  }, [props.featured, props.contentTypes, props.collectionId, props.tags]);

  if (!list.length) {
    return null;
  }

  return (
    <Root>
      <Title>
        <TitleText>{capacityFirst(t(props.title))}</TitleText>
        {!!props.target && (
          <TextButton
            onClick={() => {
              navigate({
                search: createSearchQuery({
                  ...query,
                  tab: props.target,
                }),
              });
            }}
          >
            <SeeAllText in={true} orientation="horizontal">
              See all
            </SeeAllText>
            <RightArrowIcon fontSize="inherit" />
          </TextButton>
        )}
      </Title>
      <List>
        {list.map((item) => (
          <Item key={item.id}>
            <ItemComponent data={item} />
          </Item>
        ))}
      </List>
    </Root>
  );
});

const Root = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  :hover {
    .collapse {
      width: auto;
    }
  }

  svg {
    /* margin-left: 4px; */
    color: #14ada6;
  }
`;

const TextButton = styled(Button)`
  padding: 4px;
  margin-left: 12px;
  min-width: 0;
  border-radius: 4px;
`;

const TitleText = styled.span`
  font-weight: 600;
  font-size: 20px;
  line-height: 150%;
`;

const SeeAllText = styled(Collapse)`
  padding: 0 4px;
  font-size: 13px;
  color: #14ada6;
  white-space: nowrap;
  height: 100%;
`;

const List = styled.ul`
  overflow-x: scroll;
  padding: 8px 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
`;

const Item = styled.li`
  margin: 0 8px;
  :first-child {
    margin-left: 0;
  }
  :last-child {
    margin-right: 0;
  }
`;
