import styled from '@emotion/styled';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../../../../components/Button';
import Image from '../../../../components/Image';

type Props = {
  selected: boolean;
  label: string;
  icon: string;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

export default function TabItem(props: Props) {
  const { t } = useTranslation();
  return (
    <Root
      selected={props.selected}
      // @ts-ignore
      onClick={props.onClick}
      component="div"
    >
      <Label>{t(props.label)}</Label>
      <ImageIcon className="image-icon" src={props.icon} />
    </Root>
  );
}

const Root = styled(Button)<{ selected: boolean }>`
  opacity: ${({ selected }) => (selected ? 1 : 0.5)};
  color: ${({ selected }) => (selected ? '#FF8A00' : 'inherit')};
  display: flex;
  flex-direction: column;
  padding: 8px;
  transition: ${({ selected }) =>
    selected ? 'opacity 400ms ease-in-out' : undefined};
  border-radius: 8px;
  margin: 8px;
  box-sizing: content-box;
  justify-content: center;
  align-items: center;
  width: 80px;
  scroll-snap-align: center;

  .image-icon {
    /* border: ${({ selected }) => (selected ? 'none' : undefined)}; */
  }
`;

const Label = styled.div`
  text-align: center;
  text-transform: uppercase;
  margin-bottom: 8px;
  font-size: 12px;
  white-space: nowrap;
  font-weight: 600;
`;

const ImageIcon = styled(Image)`
  border-radius: 12px;
  width: 62px;
  height: 62px;
  box-sizing: border-box;
  object-fit: cover;
  border: 2px solid rgba(47, 96, 93, 0.3);
  pointer-events: none;
`;
