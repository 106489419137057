import { SvgIcon, SvgIconProps } from '@mui/material';

export function MdiIcon({ d, ...rest }: SvgIconProps & { d?: string }) {
  return (
    <SvgIcon {...rest}>
      <path d={d} />
    </SvgIcon>
  );
}

function withCustomProps(cp: {
  viewBox?: string;
  d?: string;
  g?: React.ReactNode;
}) {
  return (p: SvgIconProps) => (
    <SvgIcon viewBox={cp.viewBox} {...p}>
      {cp.g ? cp.g : <path d={cp.d} />}
    </SvgIcon>
  );
}
const dMusicNote =
  'm340.681 135.739c-13.152-26.976-35.744-42.112-57.568-56.736-16.288-10.912-31.68-21.216-42.56-35.936l-1.952-2.624c-6.432-8.64-13.696-18.432-14.848-26.656-1.152-8.32-8.704-14.24-16.96-13.76-8.384.576-14.88 7.52-14.88 15.936v285.12c-13.408-8.128-29.92-13.12-48-13.12-44.096 0-80 28.704-80 64s35.904 64 80 64 80-28.704 80-64v-279.008c12.544 13.312 27.136 23.104 41.376 32.64 19.168 12.832 37.28 24.96 46.656 44.192 5.888 12 8.704 25.088 8 36.736-1.76 28.512-12.512 57.184-28.032 74.88-5.856 6.624-5.216 16.736 1.44 22.56 6.592 5.888 16.704 5.184 22.56-1.44 20.032-22.752 33.824-58.784 35.968-94.016 1.055-17.376-2.816-35.616-11.2-52.768z';
const dPause =
  'M13.987,0c-2.762,0-5,2.239-5,5v35.975c0,2.763,2.238,5,5,5s5-2.238,5-5V5C18.987,2.238,16.75,0,13.987,0 M31.987,0c-2.762,0-5,2.239-5,5v35.975c0,2.762,2.238,5,5,5s5-2.238,5-5V5C36.987,2.239,34.749,0,31.987,0z';
const dTimer =
  'm24 45.5c10.75 0 19.5-8.75 19.5-19.5 0-10.25-7.95-18.65-18-19.42v-1.08h2.5c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-8c-.83 0-1.5.67-1.5 1.5s.67 1.5 1.5 1.5h2.5v1.08c-10.05.77-18 9.17-18 19.42 0 10.75 8.75 19.5 19.5 19.5zm0-36c9.1 0 16.5 7.4 16.5 16.5s-7.4 16.5-16.5 16.5-16.5-7.4-16.5-16.5 7.4-16.5 16.5-16.5z m0 18h8c.83 0 1.5-.67 1.5-1.5s-.67-1.5-1.5-1.5h-6.5v-8.5c0-.83-.67-1.5-1.5-1.5s-1.5.67-1.5 1.5v10c0 .83.67 1.5 1.5 1.5z';
const dPlay =
  'm37.324 20.026-22-12.412a4.685 4.685 0 0 0 -4.711.036 4.528 4.528 0 0 0 -2.28 3.938v24.824a4.528 4.528 0 0 0 2.28 3.938 4.687 4.687 0 0 0 4.711.036l22-12.412a4.543 4.543 0 0 0 0-7.948z';
const dSearch =
  'm185.746 371.332c41.251.001 81.322-13.762 113.866-39.11l122.778 122.778c9.172 8.858 23.787 8.604 32.645-.568 8.641-8.947 8.641-23.131 0-32.077l-122.778-122.778c62.899-80.968 48.252-197.595-32.716-260.494s-197.594-48.252-260.493 32.716-48.252 197.595 32.716 260.494c32.597 25.323 72.704 39.06 113.982 39.039zm-98.651-284.273c54.484-54.485 142.82-54.486 197.305-.002s54.486 142.82.002 197.305-142.82 54.486-197.305.002c-.001-.001-.001-.001-.002-.002-54.484-54.087-54.805-142.101-.718-196.585.239-.24.478-.479.718-.718z';
const dRightArrow =
  'm8.6585 4.24744c-.29529-.25838-.7144-.32019-1.07172-.15806-.35731.16214-.58678.51826-.58678.91064v13.99998c0 .3924.22947.7485.58678.9106.35732.1622.77643.1004 1.07172-.158l8-7c.217-.1899.3415-.4642.3415-.7526 0-.2883-.1245-.5627-.3415-.7526z';
const dChevronDown =
  'm64 88c-1.023 0-2.047-.391-2.828-1.172l-40-40c-1.563-1.563-1.563-4.094 0-5.656s4.094-1.563 5.656 0l37.172 37.172 37.172-37.172c1.563-1.563 4.094-1.563 5.656 0s1.563 4.094 0 5.656l-40 40c-.781.781-1.805 1.172-2.828 1.172z';
const dReload =
  'm29.89063 18.24023a1.49876 1.49876 0 0 0 -1.86524 1.00928 11.42788 11.42788 0 0 1 -5.54883 6.833c-8.84956 4.90724-19.48356-3.72189-16.49496-13.39518 2.14425-7.9168 12.65017-10.9106 18.64285-5.32257l-2.352-.15382a1.49992 1.49992 0 0 0 -.19525 2.99316l5.871.38367a1.52376 1.52376 0 0 0 1.586-1.70156l-.80467-5.83006a1.50041 1.50041 0 0 0 -2.97265.41016l.14648 1.06055a14.49643 14.49643 0 1 0 4.99703 15.57914 1.50023 1.50023 0 0 0 -1.00976-1.86577z';
export const MusicNoteIcon = withCustomProps({
  viewBox: '-48 -48 512 512',
  d: dMusicNote,
});

export const PauseIcon = withCustomProps({
  d: dPause,
  viewBox: '-6 -6 58 58',
});

export const TimerIcon = withCustomProps({
  d: dTimer,
  viewBox: '0 0 48 48',
});

export const PlayIcon = withCustomProps({
  d: dPlay,
  viewBox: '-3 0 48 48',
});

export const SearchIcon = withCustomProps({
  d: dSearch,
  viewBox: '-80 -80 620 620',
});

export const RightArrowIcon = withCustomProps({
  d: dRightArrow,
  viewBox: '0 0 24 24',
});

export const FeedbackIcon = withCustomProps({
  viewBox: '0 0 681.33304 681',
  g: (
    <g>
      <path d="m634.070312 67.636719-63.878906-63.875c-6.226562-6.230469-16.332031-6.238281-22.566406-.007813 0 .003906-.003906.007813-.011719.007813l-191.621093 191.628906c-2.996094 2.992187-4.675782 7.050781-4.675782 11.285156v63.878907c0 8.816406 7.148438 15.964843 15.964844 15.964843h63.878906c4.234375 0 8.292969-1.679687 11.285156-4.675781l191.625-191.625c6.234376-6.230469 6.238282-16.335938.011719-22.570312-.003906 0-.003906-.007813-.011719-.011719zm-209.523437 186.949219h-41.296875v-41.292969l175.65625-175.65625 41.292969 41.292969zm0 0"></path>
      <path d="m590.839844 190.710938c-8.816406 0-15.964844 7.148437-15.964844 15.964843v223.5625c-.027344 26.445313-21.460938 47.878907-47.90625 47.910157h-159.6875c-3.457031-.007813-6.820312 1.113281-9.582031 3.191406l-102.199219 76.652344v-63.875c0-8.820313-7.148438-15.96875-15.96875-15.96875h-159.6875c-26.445312-.03125-47.878906-21.464844-47.902344-47.90625v-287.441407c.027344-26.441406 21.457032-47.875 47.902344-47.902343h287.4375c8.820312 0 15.96875-7.148438 15.96875-15.96875 0-8.816407-7.148438-15.964844-15.96875-15.964844h-287.4375c-44.078125.042968-79.8007812 35.765625-79.84375 79.835937v287.441407c.0429688 44.074218 35.765625 79.796874 79.84375 79.84375h143.71875v79.84375c0 8.816406 7.148438 15.964843 15.96875 15.964843 3.457031 0 6.816406-1.121093 9.582031-3.191406l123.507813-92.621094h154.347656c44.078125-.042969 79.796875-35.765625 79.84375-79.84375v-223.558593c0-8.820313-7.152344-15.96875-15.972656-15.96875zm0 0"></path>
      <path d="m271.464844 158.773438h-159.679688c-8.824218 0-15.972656 7.148437-15.972656 15.96875 0 8.816406 7.148438 15.964843 15.972656 15.964843h159.679688c8.820312 0 15.972656-7.148437 15.972656-15.964843 0-8.820313-7.152344-15.96875-15.972656-15.96875zm0 0"></path>
      <path d="m111.785156 286.523438h159.679688c8.820312 0 15.972656-7.152344 15.972656-15.96875 0-8.820313-7.152344-15.96875-15.972656-15.96875h-159.679688c-8.824218 0-15.972656 7.148437-15.972656 15.96875 0 8.816406 7.148438 15.96875 15.972656 15.96875zm0 0"></path>
      <path d="m111.785156 382.332031h383.246094c8.820312 0 15.964844-7.148437 15.964844-15.96875 0-8.816406-7.148438-15.964843-15.964844-15.964843h-383.246094c-8.824218 0-15.972656 7.148437-15.972656 15.964843 0 8.820313 7.148438 15.96875 15.972656 15.96875zm0 0"></path>
    </g>
  ),
});

export const ChevronDownIcon = withCustomProps({
  d: dChevronDown,
  viewBox: '0 0 128 128',
});

export const EmailIcon = withCustomProps({
  d: 'm29.761 6.515s0-.001-.001-.001c-.005-.006-.012-.008-.017-.013-.583-.561-1.372-.91-2.243-.91h-23c-.872 0-1.661.349-2.245.91-.004.005-.011.007-.016.013 0 0 0 .001-.001.001-.607.591-.988 1.414-.988 2.326v14.317c0 1.792 1.458 3.25 3.25 3.25h23c1.792 0 3.25-1.458 3.25-3.25v-14.317c0-.912-.381-1.735-.989-2.326zm-2.261.576c.131 0 .257.017.379.044l-10.742 9.184c-.66.564-1.615.564-2.275 0l-10.741-9.184c.122-.027.248-.044.379-.044zm1.75 16.068c0 .965-.785 1.75-1.75 1.75h-23c-.965 0-1.75-.785-1.75-1.75v-14.318c0-.271.067-.524.177-.753l10.96 9.372c.613.523 1.363.785 2.113.785s1.5-.262 2.112-.785l10.96-9.371c.11.229.177.483.177.753v14.317z',
  viewBox: '0 0 32 32',
});

export const ReloadIcon = withCustomProps({
  d: dReload,
  viewBox: '1 0 32 32',
});
