import styled from '@emotion/styled';
import { Dialog, IconButton } from '@mui/material';

export const Root = styled(Dialog)`
  display: flex;
  flex-direction: column;
`;

export const PopupAction = styled.div`
  display: flex;
  justify-content: flex-end;
`;

export const Main = styled.div`
  background: linear-gradient(229.28deg, #1a4240 13.38%, #122221 85.98%);
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  position: relative;
  @media (min-width: 600px) {
    /* width */
    ::-webkit-scrollbar {
      width: 10px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #1a4240;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #001c1a;
      border-radius: 8px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #002724;
    }
  }
`;

export const Container = styled.div`
  flex: 1;
  /* overflow: auto; */
  padding: 20px;

  @media (min-width: 681px) {
    // 2 items
    padding: 20px calc(50% - 322px);
  }
  @media (min-width: 1000px) {
    // 3 items
    padding: 20px calc(50% - 480px);
  }
  @media (min-width: 1400px) {
    // 4 items
    padding: 20px calc(50% - 638px);
  }
  @media (min-width: 1800px) {
    // 5 items
    padding: 20px calc(50% - 796px);
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin: 8px;

  &.MuiIconButton-colorPrimary {
    color: #001c1a;
    background-color: #6be5d7;
    :hover {
      background-color: #5acdc0;
    }
  }
  &.MuiIconButton-colorSecondary {
    color: inherit;
    background-color: rgba(0, 194, 188, 0.2);
    :hover {
      background-color: rgba(5, 142, 137, 0.2);
    }
  }
  &.MuiIconButton-sizeSmall {
    width: 36px;
    height: 36px;
    font-size: 18px;
  }
  &.Mui-disabled {
    color: #bbbbbb;
    background-color: rgba(108, 147, 146, 0.67);
  }
`;

export const Header = styled.div`
  position: sticky;
  top: -222px;
  left: 0;
  right: 0;
  z-index: 100;
`;
