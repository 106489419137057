import Dexie from 'dexie';

const DB_NAME = 'AppDB';

export default class AppDB extends Dexie {
  public static MEDIA_OFFLINE_BUFFER_LENGTH = 1024 * 1024;
  private static _instance: AppDB;

  public static get instance() {
    if (!this._instance) {
      this._instance = new AppDB();
    }
    return this._instance;
  }

  public offlineMedia!: Dexie.Table<OfflineMedia, string>;
  private constructor() {
    super(DB_NAME);
    this.version(1).stores({
      offlineMedia: `id,hash,part,data`,
    });
  }

  async storeOfflineMediaAsync(hash: string, data: ArrayBuffer) {
    const partCount = Math.ceil(
      data.byteLength / AppDB.MEDIA_OFFLINE_BUFFER_LENGTH
    );
    for (let part = 0; part < partCount; part++) {
      const buff = new Uint8Array(
        data.slice(
          part * AppDB.MEDIA_OFFLINE_BUFFER_LENGTH,
          Math.min(
            (part + 1) * AppDB.MEDIA_OFFLINE_BUFFER_LENGTH,
            data.byteLength
          )
        )
      );
      await this.saveApartOfflineMediaAsync(hash, part, buff);
    }
  }

  saveApartOfflineMediaAsync(hash: string, part: number, data: Uint8Array) {
    const key = `${hash}|${part}`;
    return this.offlineMedia.put(
      {
        id: key,
        data,
        hash,
        part,
      },
      key
    );
  }

  getOfflineMediaAsync(hash: string, part: number) {
    const key = `${hash}|${part}`;
    return this.offlineMedia.get(key);
  }

  getOfflineMediaDataAsync(hash: string) {
    return this.offlineMedia
      .where({ hash })
      .sortBy('part')
      .then((res) => {
        if (!res.length) {
          return undefined;
        }
        let len = 0;
        for (const apart of res) {
          len += apart.data.byteLength;
        }
        const buff = new Int8Array(len);
        let i = 0;

        for (const apart of res) {
          buff.set(apart.data, i);
          i += apart.data.byteLength;
        }
        return buff;
      });
  }
}

export interface OfflineMedia {
  id: string;
  hash: string;
  part: number;
  data: Uint8Array;
}
