import styled from '@emotion/styled';
import Button from '../../../../../components/Button';
import { PauseIcon, PlayIcon } from '../../../../../components/icons';
import Image from '../../../../../components/Image';
import { useCurrentMedio, usePlayerState } from '../../../../../hooks/player';
import { Medio } from '../../../../../models/medio';
import MusicPlayer from '../../../../../controllers/MusicPlayer';
import { StyledIconButton } from '../styled';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../../../hooks/use-query';
import { createSearchQuery } from '../../../../../utils/query';

type Props = {
  data: Medio;
};

export default function FeaturedItem(props: Props) {
  const currentMedio = useCurrentMedio();
  const playerState = usePlayerState();
  const navigate = useNavigate();
  const query = useQuery();

  const isPlaying =
    currentMedio?.id === props.data.id && playerState === 'play';

  const PlayerIcon = isPlaying ? PauseIcon : PlayIcon;

  return (
    <Root
      disableRipple
      onClick={(e) => {
        navigate({
          search: createSearchQuery({
            ...query,
            medio: props.data.id,
          }),
        });
      }}
    >
      <BgImage src={props.data.photoURL} loading="lazy" />
      <Container>
        <Title>
          <div
            style={{
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {props.data.title}
          </div>
          <div className="play-container">
            <StyledIconButton
              onClick={(e) => {
                e.stopPropagation();
                if (!isPlaying) {
                  MusicPlayer.setMedio(props.data);
                  return;
                }
                MusicPlayer.pause();
              }}
              color="primary"
              size="small"
            >
              <PlayerIcon fontSize="inherit" />
            </StyledIconButton>
          </div>
        </Title>
        <Description>{props.data.shortDescription}</Description>
      </Container>
    </Root>
  );
}

const Root = styled(Button)`
  height: 212px;
  max-width: 300px;
  width: calc(100vw - 80px);
  border-radius: 16px;
  display: block;
  position: relative;
  overflow: hidden;
  text-align: left;
  padding: 0;
  min-width: 0;
  color: inherit;
`;

const BgImage = styled(Image)`
  position: absolute;
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  height: 100%;
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  background: linear-gradient(180deg, rgba(10, 20, 40, 0) 0%, #0a1428 100%);
  .play-container {
    visibility: hidden;
    position: absolute;
    bottom: 0;
    right: 0;
    animation: fade-out 300ms ease-in-out;
    transition: visibility 300ms linear;
  }
  :hover {
    .play-container {
      visibility: visible;
      animation: fade-in 300ms ease-in-out;
    }
  }
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  position: relative;
  display: flex;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 4px;
  min-height: 2.8em;
  line-clamp: 3;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
