import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux';
import { SnackbarProvider } from 'notistack';
import Routing from './Routing';
import DialogContext from './context/DialogContext';
import i18n from './lang/i18n';
import { CircularProgress } from '@mui/material';
import moment from 'moment';
import 'moment/locale/vi';

//@ts-ignore
window.i18n = i18n;
moment.locale(i18n.language);
i18n.on('languageChanged', (lng) => {
  moment.locale(lng);
});

function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={<CircularProgress />} persistor={persistor}>
        <DialogContext>
          <SnackbarProvider
            maxSnack={3}
            anchorOrigin={{
              horizontal: 'center',
              vertical: 'bottom',
            }}
          >
            <Routing />
          </SnackbarProvider>
        </DialogContext>
      </PersistGate>
    </Provider>
  );
}

export default App;
