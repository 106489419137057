import { ContentType, FilterType } from './models/collection';

export const tabConfigs: {
  key: string;
  title: string;
  tags: string[];
  collection?: number;
  contentTypes?: ContentType[];
  filterType: FilterType;
  isWellness?: boolean;
}[] = [
  {
    key: 'wellness-music',
    title: 'wellness-music',
    filterType: FilterType.Classified,
    tags: [],
    isWellness: true,
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'favorite',
    title: 'favorite',
    filterType: FilterType.Favorite,
    tags: [],
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'beginner',
    title: 'beginner',
    filterType: FilterType.Classified,
    tags: [],
    collection: 2,
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'sleep',
    title: 'for-sleep',
    filterType: FilterType.Classified,
    tags: [],
    collection: 1,
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'relax',
    title: 'relax-stress',
    filterType: FilterType.Classified,
    tags: [],
    collection: 3,
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'focus',
    title: 'focus',
    filterType: FilterType.Classified,
    tags: [],
    collection: 4,
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'natural-sound',
    filterType: FilterType.Classified,
    title: 'natural sound',
    tags: ['nature-sound'],
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
];
export const sectionConfigs: {
  key: string;
  title: string;
  featured: boolean;
  tags: string[];
  target?: string;
  collection?: number;
  contentTypes?: ContentType[];
}[] = [
  {
    key: 'featured',
    title: 'featured',
    featured: true,
    tags: [],
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'beginner',
    title: 'beginner',
    target: 'beginner',
    featured: false,
    tags: [],
    collection: 2,
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'sleep',
    title: 'sleep',
    target: 'sleep',
    featured: false,
    tags: [],
    collection: 1,
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'relax',
    title: 'relax',
    target: 'relax',
    featured: false,
    tags: [],
    collection: 3,
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'focus',
    title: 'focus',
    target: 'focus',
    featured: false,
    tags: [],
    collection: 4,
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
  {
    key: 'natural-sound',
    title: 'natural sound',
    target: 'natural-sound',
    featured: false,
    tags: ['nature-sound'],
    contentTypes: [
      ContentType.Meditation,
      ContentType.Song,
      ContentType.Soundscape,
      ContentType.Story,
    ],
  },
];
