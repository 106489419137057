import styled from '@emotion/styled';
import {
  mdiDotsVertical,
  mdiBookOpenVariant,
  mdiInformationOutline,
  mdiWeb,
} from '@mdi/js';
import { ListItemIcon, Menu, MenuItem } from '@mui/material';
import React, { useRef, useState } from 'react';
import { Trans } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { MdiIcon } from '../../../../components/icons';
import { AppActions } from '../../../../redux/app';
import { HeaderButton } from './styled';
import packageJson from '../../../../../package.json';

type Props = {};

export default function OptionButton(props: Props) {
  const btnRef = useRef<HTMLButtonElement | null>(null);
  const [open, setOpen] = useState(false);
  const dispatch = useDispatch();

  return (
    <>
      <HeaderButton
        ref={btnRef}
        color="inherit"
        onClick={() => {
          setOpen(true);
        }}
      >
        <MdiIcon fontSize="inherit" d={mdiDotsVertical} />
      </HeaderButton>
      <Menu
        anchorEl={btnRef.current}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        PaperProps={{
          elevation: 0,
          sx: {
            background: '#172B2A',
            color: 'inherit',
            overflow: 'visible',
            filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
            mt: 1.5,
            '& .MuiAvatar-root': {
              width: 32,
              height: 32,
              ml: -0.5,
              mr: 1,
            },
          },
        }}
        open={open}
        onClose={() => setOpen(false)}
      >
        <MenuItem
          onClick={() => {
            setOpen(false);
            dispatch(AppActions.setAboutUsOpen(true));
          }}
        >
          <StyledListIcon>
            <MdiIcon
              fontSize="small"
              d={mdiInformationOutline}
              color="inherit"
            />
          </StyledListIcon>
          <span className="text text-small text-medium">
            <Trans>About</Trans>
          </span>
        </MenuItem>
        <MenuItem
          onClick={() => {
            setOpen(false);
            dispatch(AppActions.setLanguageOpen(true));
          }}
        >
          <StyledListIcon>
            <MdiIcon fontSize="small" d={mdiWeb} color="inherit" />
          </StyledListIcon>
          <span className="text text-small text-medium">
            <Trans>Language</Trans>
          </span>
        </MenuItem>
        {/* <MenuItem
          onClick={() => {
            setOpen(false);
            window.open(process.env.REACT_APP_GUIDE_URL, '_blank');
          }}
        >
          <StyledListIcon>
            <MdiIcon fontSize="small" d={mdiBookOpenVariant} color="inherit" />
          </StyledListIcon>
          <span className="text text-small text-medium">
            <Trans>User guides</Trans>
          </span>
        </MenuItem> */}
        <div style={{ padding: '8px 16px' }}>
          Version: {packageJson.version}
        </div>
      </Menu>
    </>
  );
}

const StyledListIcon = styled(ListItemIcon)`
  color: var(--default-text-color);
`;
