import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import { AppActionEnum, AppActionTypes } from './action';

function readBrowserLang() {
  if (navigator.language.toLowerCase().match(/vi(-vn)?/)) {
    return 'vi';
  }
  return 'en';
}

type AppState = {
  title: string;
  aboutUsOpen?: boolean;
  languageOpen?: boolean;
  guidesOpen?: boolean;
  lang: string;
};

const initState = Object.freeze<AppState>({
  title: '',
  lang: readBrowserLang(),
});

function reducer(state = initState, action: AppActionTypes): AppState {
  switch (action.type) {
    case AppActionEnum.SET_APP_BAR_TITLE: {
      return {
        ...state,
        title: action.payload.title,
      };
    }
    case AppActionEnum.SET_ABOUT_US_OPEN: {
      return {
        ...state,
        aboutUsOpen: action.payload,
      };
    }
    case AppActionEnum.SET_LANGUAGE_OPEN: {
      return {
        ...state,
        languageOpen: action.payload,
      };
    }
    case AppActionEnum.SET_GUIDES_OPEN: {
      return {
        ...state,
        guidesOpen: action.payload,
      };
    }
    case AppActionEnum.SET_LANGUAGE: {
      return {
        ...state,
        lang: action.payload,
      };
    }
    default: {
      return state;
    }
  }
}

export default persistReducer(
  {
    key: 'app',
    storage: storage,
    whitelist: ['lang'],
  },
  reducer
);
