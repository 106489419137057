import { store } from '../redux';
import { AuthActions } from '../redux/auth';

export function authAction(callback?: () => void) {
  const { auth } = store.getState();
  if (!auth.firebaseAuthLoading) {
    const isAuth = auth.firebaseUser && auth.mindfullyAuth;
    if (isAuth) {
      callback?.();
      return;
    }
    if (!auth.loginPopupOpen) {
      store.dispatch(AuthActions.setLoginPopupOpen(true));
    }
  }
}
