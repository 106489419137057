import styled from '@emotion/styled';
import Button from '../../components/Button';
import { StyledTextField } from '../../components/FormItem';

export const Root = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px;
  box-sizing: border-box;
  width: 100%;
`;

export const Form = styled.form`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  color: #fff;
  padding: 0 16px 24px 16px;
  border-radius: 16px;
  box-sizing: border-box;
`;

export const Title = styled.div`
  box-sizing: border-box;
  font-weight: 700;
  text-align: center;
  margin-top: 8%;
  font-size: 24px;
  padding: 16px 0;
`;

export const LoginTitle = styled.div`
  font-size: 14px;
  margin-bottom: 8px;
  text-align: center;
`;

export const Footer = styled.div`
  box-sizing: border-box;
  height: 20%;
  min-height: 64px;
  align-items: center;
  justify-content: center;
  display: flex;
`;

export const TextField = styled(StyledTextField)`
  .MuiOutlinedInput-root {
    background-color: #fff;
  }
  /* :hover {
    .MuiOutlinedInput-notchedOutline {
      border-color: rgba(255, 255, 255, 0.8);
    }
  }
  .MuiOutlinedInput-notchedOutline {
    border-color: rgba(255, 255, 255, 0.58);
  }
  .Mui-focused {
    .MuiOutlinedInput-notchedOutline {
      border: 2px solid rgb(255, 255, 255);
    }
  }
  .MuiInputAdornment-root {
    color: rgba(255, 255, 255, 0.704);
  } */
  .MuiFormHelperText-root.Mui-error {
    color: #ba2121;
  }

  /* input {
    ::placeholder {
      color: rgba(255, 255, 255, 0.704);
    }
  } */
`;

export const LoginButton = styled(Button)`
  background-color: #001c1a;
  :hover {
    background-color: #041e1d;
  }
  :disabled {
    background-color: #7b7b7b;
    color: #b0afaf;
  }
`;

export const SocialLoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-bottom: 32px;
  width: 100%;
  padding: 0 16px;
  box-sizing: border-box;

  .social-button {
    color: inherit;
    border-color: rgba(255, 255, 255, 0.3);
    margin: 4px;

    .social-button-text {
      flex: 1;
      max-width: 200px;
      font-weight: 500;
    }
    :first-of-type {
      margin-top: 0;
    }
    :last-of-type {
      margin-bottom: 0;
    }
  }
`;

export const SocialLogo = styled.img`
  width: 32px;
  height: 32px;
`;
