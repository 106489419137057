import styled from '@emotion/styled';
import React from 'react';
import Button from '../../../../../components/Button';
import { PauseIcon, PlayIcon } from '../../../../../components/icons';
import Image from '../../../../../components/Image';
import { useCurrentMedio, usePlayerState } from '../../../../../hooks/player';
import { Medio } from '../../../../../models/medio';
import MusicPlayer from '../../../../../controllers/MusicPlayer';
import { StyledIconButton } from '../styled';
import { useNavigate } from 'react-router-dom';
import useQuery from '../../../../../hooks/use-query';
import { createSearchQuery } from '../../../../../utils/query';

type Props = {
  data: Medio;
};

export default function ContentItem(props: Props) {
  const currentMedio = useCurrentMedio();
  const playerState = usePlayerState();
  const navigate = useNavigate();
  const query = useQuery();

  const isPlaying =
    currentMedio?.id === props.data.id && playerState === 'play';

  const PlayerIcon = isPlaying ? PauseIcon : PlayIcon;
  return (
    <Root
      disableRipple
      onClick={(e) => {
        navigate({
          search: createSearchQuery({
            ...query,
            medio: props.data.id,
          }),
        });
      }}
    >
      <CoverContainer>
        <CoverImage src={props.data.photoURL} loading="lazy" />
        <div className="play-container">
          <StyledIconButton
            onClick={(e) => {
              e.stopPropagation();
              if (!isPlaying) {
                MusicPlayer.setMedio(props.data);
                return;
              }
              MusicPlayer.pause();
            }}
            color="primary"
            size="small"
          >
            <PlayerIcon fontSize="inherit" />
          </StyledIconButton>
        </div>
      </CoverContainer>
      <Container>
        <Title>{props.data.title}</Title>
        <Description>{props.data.shortDescription}</Description>
      </Container>
    </Root>
  );
}

const Root = styled(Button)`
  height: 238px;
  max-width: 300px;
  width: calc(100vw - 80px);
  padding: 0;
  border-radius: 0;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  text-align: left;
  color: inherit;

  .play-container {
    z-index: 10;
    visibility: hidden;
    position: absolute;
    bottom: 8px;
    right: 0;
    animation: fade-out 300ms ease-in-out;
    transition: visibility 300ms linear;
  }
  :hover {
    .play-container {
      visibility: visible;
      animation: fade-in 300ms ease-in-out;
    }
  }
`;

const CoverImage = styled(Image)`
  width: 100%;
  height: 130px;
  border-radius: 16px;
  object-fit: cover;
  object-position: center;
`;

const CoverContainer = styled.div`
  position: relative;
  width: 100%;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  margin-top: 12px;
  /* height: 88px; */
  width: 100%;
  box-sizing: border-box;
`;

const Title = styled.div`
  font-weight: 600;
  font-size: 16px;
  line-height: 150%;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  @media (max-width: 600px) {
    font-size: 14px;
  }
`;

const Description = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  min-height: 4.2em;
  color: rgba(255, 255, 255, 0.7);
  margin-top: 4px;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  @media (max-width: 600px) {
    font-size: 12px;
  }
`;
