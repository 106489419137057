import { applyMiddleware, createStore } from 'redux';
import { persistedReducer } from './reducer';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { persistStore } from 'redux-persist';

const isDev = process.env.REACT_APP_ENV === 'development';

const middlewares: any[] = [thunk];
if (isDev) {
  middlewares.push(logger);
}

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middlewares)
);

export const persistor = persistStore(store);

export type { RootState } from './reducer';
