import styled from '@emotion/styled';
import React, { useEffect, useState } from 'react';
import { sectionConfigs } from '../../../../../app-config';
import useQuery from '../../../../../hooks/use-query';
import Section from './Section';

type Props = {};

export default function AllTabContent(props: Props) {
  const query = useQuery();
  const shouldVisible = !query.tab || query.tab === 'all';
  const [mounted, setMounted] = useState(shouldVisible);

  useEffect(() => {
    if (shouldVisible && !mounted) {
      setMounted(true);
    }
  }, [shouldVisible, mounted]);

  if (!mounted) {
    return null;
  }

  return (
    <Root visible={shouldVisible}>
      {sectionConfigs.map((section) => (
        <Section
          key={section.key}
          title={section.title}
          featured={section.featured}
          tags={section.tags}
          contentTypes={section.contentTypes}
          collectionId={section.collection}
          target={section.target}
        />
      ))}
    </Root>
  );
}

const Root = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;
