import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { AuthActions } from './redux/auth';
import { useSnackbar } from 'notistack';
import MusicPlayer from './controllers/MusicPlayer';
import HomeRouting from './public-pages/home';
import i18n from './lang/i18n';
import { RootState } from './redux';
import { myselfApi } from './api/myself';
import { UserActions } from './redux/user';
import { isSignInWithEmailLink, signInWithEmailLink } from 'firebase/auth';
import { auth as firebaseAuth } from './firebase';
import storage from './helper/storage';
import { SocialProviderEnum } from './models/auth';
import authApi from './api/auth';
import { useLanguage } from './hooks/app';
import { MeditationActions } from './redux/meditation';

type Props = {};

export default function Routing(props: Props) {
  const { enqueueSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const lang = useLanguage();
  const auth = useSelector((state: RootState) => state.auth);
  useEffect(() => {
    dispatch(AuthActions.firebaseAuthInit());
  }, [dispatch]);

  useEffect(() => {
    if (auth.mindfullyAuth) {
      myselfApi.getProfile().then((res) => {
        dispatch(UserActions.setProfile(res));
      });
    }
  }, [auth.mindfullyAuth, dispatch]);

  useEffect(() => {
    const listener = (err: any) => {
      enqueueSnackbar(err?.message ?? err?.toString() ?? 'Player error', {
        variant: 'error',
      });
    };

    MusicPlayer.on('error', listener);

    return () => {
      MusicPlayer.off('error', listener);
    };
  }, [enqueueSnackbar]);

  useEffect(() => {
    if (isSignInWithEmailLink(firebaseAuth, window.location.href)) {
      const email = storage.getLoginWithEmail();
      if (!email) {
        return;
      }

      dispatch(AuthActions.setIsAuthenticating(true));

      // The client SDK will parse the code from the link for you.
      signInWithEmailLink(firebaseAuth, email, window.location.href)
        .then((userCredential) => {
          // Clear email from storage.
          storage.setLoginWithEmail();
          userCredential.user
            .getIdToken()
            .then((token) => authApi.login(token, SocialProviderEnum.EMAIL))
            .then((res) => {
              if (res.tokens) {
                storage.setTokens(res.tokens);
                dispatch(AuthActions.setAuth(true));
                if (res.profile) {
                  dispatch(UserActions.setProfile(res.profile));
                }
                return;
              }
              throw Error('authenticate-failed');
            });
        })
        .catch((error) => {
          console.error(error);
          // Some error occurred, you can inspect the code: error.code
          // Common errors could be invalid email and invalid or expired OTPs.
        })
        .finally(() => {
          dispatch(AuthActions.setIsAuthenticating(false));
        });
    }
  }, [dispatch]);

  useEffect(() => {
    i18n.changeLanguage(lang);
    dispatch(MeditationActions.fetchTopics());
  }, [lang, dispatch]);

  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/*" element={<HomeRouting />} />
        </Routes>
      </BrowserRouter>
    </>
  );
}
