import axios from 'axios';
import md5 from 'blueimp-md5';
import AppDB from '../db/cached';

const download = (url: string) => {
  const hash = md5(url);
  return AppDB.instance.getOfflineMediaDataAsync(hash).then((byteArray) => {
    if (byteArray) {
      return byteArray.buffer;
    }
    return axios
      .request<ArrayBuffer>({
        method: 'GET',
        url,
        responseType: 'arraybuffer',
      })
      .then(async (res) => {
        await AppDB.instance.storeOfflineMediaAsync(hash, res.data);
        return res.data;
      });
  });
};

const downloadApi = {
  download,
};

export default downloadApi;
