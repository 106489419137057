import styled from '@emotion/styled';
import React from 'react';

type Props<T> = {
  itemSize: number;
  onChange?: (v: T) => void;
  options: T[];
  optionKey: (item: T, index: number) => string | number;
  format: (item: T, index: number) => React.ReactNode;
  selectedValue: T;
};

type State = {
  scrollViewHeight: number;
  scrollTop: number;
  isPressed: boolean;
};

const initState = Object.freeze<State>({
  scrollViewHeight: 0,
  scrollTop: 0,
  isPressed: false,
});

export default class WheelPicker<T> extends React.PureComponent<
  Props<T>,
  State
> {
  state = initState;
  private _rootRef = React.createRef<HTMLDivElement>();
  private _isPointerDown = false;
  private _t?: number;
  private _framet?: number;
  private _itemClicked = false;
  private _anchorClientY = 0;
  private _anchorScrollTop = 0;
  private _clientY = 0;
  private _clientYDistance = 0;
  private _decelerationRate = 0.025;
  private _minVelocity = 0.1;
  private _clearVelocity?: number;
  private __resizing?: number;

  componentDidMount() {
    this._handleResize();
    window.addEventListener('resize', this._handleResize);
  }

  componentWillUnmount(): void {
    window.removeEventListener('resize', this._handleResize);
  }

  componentDidUpdate(
    prevProps: Readonly<Props<T>>,
    prevState: Readonly<State>,
    snapshot?: any
  ): void {
    if (
      prevProps.selectedValue !== this.props.selectedValue ||
      prevState.scrollViewHeight !== this.state.scrollViewHeight
    ) {
      this._selectedItemChangeEffect();
    }
  }

  private _selectedItemChangeEffect() {
    const idx = this.props.options.indexOf(this.props.selectedValue);
    this._scrollToIndex(idx);
  }

  private _scrollToIndex(idx: number) {
    const el = this._rootRef.current;
    if (
      el &&
      this.state.scrollViewHeight &&
      Math.abs(el.scrollTop - idx * this.props.itemSize) > 5
    ) {
      this._rootRef.current?.scrollTo({
        top: idx * this.props.itemSize,
        behavior: 'smooth',
      });
    }
  }

  private _handleResize = () => {
    const rect = this._rootRef.current?.getBoundingClientRect();
    if (this.__resizing) {
      if (window.cancelIdleCallback) {
        window.cancelIdleCallback(this.__resizing);
      } else {
        window.clearTimeout(this.__resizing);
      }
    }
    if (window.requestIdleCallback) {
      this.__resizing = window.requestIdleCallback(
        () => {
          this.__resizing = undefined;
        },
        { timeout: 200 }
      );
    } else {
      this.__resizing = window.setTimeout(() => {
        this.__resizing = undefined;
      }, 200);
    }
    if (rect?.height && rect?.height !== this.state.scrollViewHeight) {
      window.requestAnimationFrame(() => {
        this.setState({ scrollViewHeight: rect?.height });
      });
    }
  };

  private _handleMouseDown = (e: React.MouseEvent) => {
    const el = this._rootRef.current;
    if (!el) {
      return;
    }
    this._anchorScrollTop = el.scrollTop;
    this.setState({ isPressed: true });
    this._anchorClientY = e.clientY;
    this._clientY = e.clientY;

    window.addEventListener('mouseup', this._handleMouseUp);
    window.addEventListener('mousemove', this._handleMouseMove);
  };

  private _handleMouseUp = (e: MouseEvent) => {
    window.removeEventListener('mouseup', this._handleMouseUp);
    window.removeEventListener('mousemove', this._handleMouseMove);

    // this._anchorClientY = e.clientY;
    this._clientY = e.clientY;
    this.setState({ isPressed: false });
    this._manualScroll(this._clientYDistance);
    this._clientYDistance = 0;
  };

  private _handleMouseMove = (e: MouseEvent) => {
    this._clientYDistance = e.clientY - this._clientY;
    this._clientY = e.clientY;
    const el = this._rootRef.current;
    if (!el) {
      return;
    }
    el.scrollTop =
      this._anchorScrollTop - (this._clientY - this._anchorClientY);
    if (this._clearVelocity) {
      window.clearTimeout(this._clearVelocity);
    }
    this._clearVelocity = window.setTimeout(() => {
      this._clientYDistance = 0;
      this._clearVelocity = undefined;
    }, 100);
  };

  private _manualScroll = (velocity = 0) => {
    const sign = -velocity / Math.abs(velocity);
    const distance = Math.abs(velocity) - this._minVelocity;
    const d = distance / this._decelerationRate;
    // const d = distance * 1000;
    if (Math.abs(velocity) > 1) {
      window.requestAnimationFrame((t) => {
        const el = this._rootRef.current;
        if (el) {
          if (
            el.scrollTop > 0 &&
            el.scrollTop < (this.props.options.length - 1) * this.props.itemSize
          ) {
            el.scrollTo({
              top: el.scrollTop + sign * d,
              behavior: 'smooth',
            });
          } else {
            this._onSnap();
          }
        }
      });
    }
  };

  private _handleTouchStart = () => {
    this._isPointerDown = true;
    window.addEventListener('touchend', this._handleTouchEnd);
  };

  private _handleTouchEnd = () => {
    this._isPointerDown = false;
    window.setTimeout(() => {
      if (this._itemClicked) {
        this._itemClicked = false;
        return;
      }
      this._onSnap();
    });
    window.removeEventListener('touchend', this._handleTouchEnd);
  };

  private _handleScroll: React.UIEventHandler = (e) => {
    const scrollView = this._rootRef.current;
    if (scrollView) {
      if (this._framet) {
        window.cancelAnimationFrame(this._framet);
      }
      this._framet = window.requestAnimationFrame(() => {
        this._framet = undefined;
        this.setState({ scrollTop: scrollView.scrollTop });
      });
    }
    if (!this._isPointerDown && !this.state.isPressed && !this.__resizing) {
      this._onSnap();
    }
  };

  private _onSnap() {
    if (this._t) {
      window.clearTimeout(this._t);
    }
    this._t = window.setTimeout(() => {
      const rootEl = this._rootRef.current;
      if (rootEl) {
        const idx = Math.floor(rootEl.scrollTop / this.props.itemSize);

        this.props.onChange?.(this.props.options[idx]);
      }
      this._t = undefined;
    }, 200);
  }

  render() {
    return (
      <Root itemSize={this.props.itemSize}>
        <ScrollView
          ref={this._rootRef}
          isPressed={this.state.isPressed}
          itemSize={this.props.itemSize}
          onScroll={this._handleScroll}
          onTouchStart={this._handleTouchStart}
          onMouseDown={this._handleMouseDown}
        >
          <List
            parentHeight={this.state.scrollViewHeight}
            itemSize={this.props.itemSize}
          >
            {this.props.options.map((option, idx) => {
              // const centerIdx = this.state.scrollTop / this.props.itemSize;
              // const distance = (centerIdx - idx) / displayedItemCount;
              // const isShow =
              //   idx > centerIdx
              //     ? idx - centerIdx + 0.5 < displayedItemCount / 2
              //     : centerIdx - idx + 0.5 < displayedItemCount / 2;
              return (
                <ItemContainer
                  className="wheel-picker-item"
                  // distance={distance}
                  key={this.props.optionKey(option, idx)}
                  itemSize={this.props.itemSize}
                  // isShow={isShow}
                  onClick={(e) => {
                    e.stopPropagation();
                    if (this._anchorClientY === this._clientY) {
                      // mouse not move => handle item click
                      this._itemClicked = true;
                      this._scrollToIndex(idx);
                    }
                  }}
                >
                  <span className="wheel-picker-item-content">
                    {this.props.format(option, idx)}
                  </span>
                </ItemContainer>
              );
            })}
          </List>
        </ScrollView>
      </Root>
    );
  }
}

type ScrollViewProps = {
  itemSize: number;
};

const Root = styled.div<ScrollViewProps>`
  flex: 1;
  position: relative;
  overflow: hidden;
  user-select: none;
  ::after {
    position: absolute;
    top: calc(50% - ${({ itemSize }) => itemSize / 2}px);
    bottom: calc(50% - ${({ itemSize }) => itemSize / 2}px);
    left: 0;
    right: 0;
    display: block;
    content: '';
    background-color: #c2c2c2;
    pointer-events: none;
    mix-blend-mode: overlay;
    border-radius: 4px;
  }
`;

const ScrollView = styled.div<ScrollViewProps & { isPressed: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  overflow-x: hidden;
  overflow-y: scroll;
  cursor: ${({ isPressed }) => (isPressed ? 'grabbing' : 'grab')};
  /* scroll-snap-type: mandatory; */
  scroll-snap-points-y: repeat(${({ itemSize }) => itemSize}px);
  scroll-snap-type: y mandatory;
  mask-image: linear-gradient(
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) calc(50% - ${({ itemSize }) => itemSize / 2}px),
    rgba(0, 0, 0, 1) calc(50% - ${({ itemSize }) => itemSize / 2}px),
    rgba(0, 0, 0, 1) calc(50% + ${({ itemSize }) => itemSize / 2}px),
    rgba(0, 0, 0, 0.3) calc(50% + ${({ itemSize }) => itemSize / 2}px),
    rgba(0, 0, 0, 0) 100%
  );

  ::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */

  scroll-behavior: ${({ isPressed }) => (isPressed ? 'auto' : 'smooth')};
  .wheel-picker-item {
    scroll-snap-align: ${({ isPressed }) => (isPressed ? undefined : 'center')};
  }
`;

const List = styled.ul<ScrollViewProps & { parentHeight: number }>`
  list-style: none;
  /* height: 100%; */
  margin: 0;
  margin-block: 0;
  padding: ${(p) => (p.parentHeight - p.itemSize) / 2}px 0;
`;

type ItemProps = ScrollViewProps & { distance?: number; isShow?: boolean };
const ItemContainer = styled.li<ItemProps>`
  /* visibility: ${({ isShow }) => (isShow ? 'visible' : 'hidden')}; */
  height: ${({ itemSize }) => itemSize}px;
  text-align: center;
  line-height: ${({ itemSize }) => itemSize}px;
  .wheel-picker-item-content {
    width: 100%;
    height: 100%;
    display: block;
    /* ${({ isShow, distance }) =>
      isShow
        ? `transform: rotateX(${(distance ?? 0) * 120}deg) scaleZ(3);`
        : 'none'}; */
  }
`;
