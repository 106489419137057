import styled from '@emotion/styled';
import { mdiChevronLeft } from '@mdi/js';
import { Dialog, IconButton, Slide } from '@mui/material';
import { TransitionProps } from '@mui/material/transitions';
import React from 'react';
import { Trans } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { MdiIcon } from '../../../../components/icons';
import { ScrollView } from '../../../../components/ScrollView';
import { RootState } from '../../../../redux';
import { AppActions } from '../../../../redux/app';
import About from './resources/about';

type Props = {};

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement<any, any>;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function AboutUsPopup(props: Props) {
  const aboutUsOpen = useSelector((state: RootState) => state.app.aboutUsOpen);
  const dispatch = useDispatch();

  return (
    <Dialog
      fullScreen
      TransitionComponent={Transition}
      PaperProps={{
        sx: {
          p: 0,
          background: '#172B2A',
          color: 'inherit',
        },
      }}
      open={!!aboutUsOpen}
    >
      <Root>
        <TopContainer>
          <IconButton
            color="inherit"
            onClick={() => {
              dispatch(AppActions.setAboutUsOpen(false));
            }}
          >
            <MdiIcon d={mdiChevronLeft} />
          </IconButton>
          <Title>
            <Trans>About</Trans>
          </Title>
          <div style={{ width: 40 }} />
        </TopContainer>
        <Content>
          <About />
        </Content>
      </Root>
    </Dialog>
  );
}

const Root = styled.div`
  box-sizing: border-box;
  flex: 1;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
`;

const TopContainer = styled.div`
  width: 100%;
  max-width: 720px;
  padding: 8px 0;
  display: flex;
  align-items: center;
`;

const Content = styled(ScrollView)`
  width: 100%;
  max-width: 720px;
  flex: 1;
  border: none;
  .scroll-container {
    padding: 20px;
    box-sizing: border-box;
  }
`;

const Title = styled.div`
  font-size: 18px;
  font-weight: 700;
  flex: 1;
  text-align: center;
`;
