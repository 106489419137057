import md5 from 'blueimp-md5';
import _ from 'lodash';
import downloadApi from '../api/download';
import { functionalMusicApis } from '../api/functional-music';
import { IChunkData, ISourceMetadata } from '../models/metadata';
import { PostMessageData } from '../models/post-message';
import { store } from '../redux';
import { MeditationActions } from '../redux/meditation';
import PlaybackController from './PlaybackController';

const SAMPLE_RATES = 44100;
const TRACK_FADE_IN_TIME = 3; // seconds
const TRACK_FADE_OUT_TIME = 5; // seconds
const TOTAL_FADE_OUT_TIME = 10;

export default class WellnessPlayer {
  // singleton
  private static _instance: WellnessPlayer;
  public static get instance() {
    if (!this._instance) {
      this._instance = new WellnessPlayer();
    }
    return this._instance;
  }

  private _ctx = new AudioContext({
    latencyHint: 'interactive',
    sampleRate: SAMPLE_RATES,
  });
  private _gain?: GainNode;
  private _fadeInOutNode?: GainNode;
  private _compressor: DynamicsCompressorNode;
  private _startedTime = 0;
  private _sentReadyToPlay = false;
  // private _readyPlay = false;
  private _tracks?: ISourceMetadata[];

  private _lastTrack?: ISourceMetadata;
  private _session = '';
  private _lastSourceNode?: AudioBufferSourceNode;
  private _firstTrackBeginTime = 0;

  public get duration() {
    if (this._lastTrack) {
      return this._lastTrack?.end;
    }
    return 0;
  }

  private _addedListener = false;

  private _topicId?: number;
  private _nextTracks?: ISourceMetadata[];

  private _isFistPlay = true;

  public blankAudio = new Audio(
    `${process.env.PUBLIC_URL}/assets/audios/10-seconds-of-silence.mp3`
  );

  public sendMessage?: (msg: PostMessageData) => void;
  public get tracks() {
    return this._tracks;
  }

  public playTopic(topicId: number) {
    this._topicId = topicId;
    this._getNextMetadataAsync(topicId).then((metadata) => {
      if (metadata) {
        this.setSources(metadata);
        return;
      }
      throw new Error('get-metadata-failed');
    });
  }

  public onplay?: () => void;
  public onpause?: () => void;
  public onError?: (error?: any) => void;
  public onLoadSource?: (source: ISourceMetadata[]) => void;

  constructor() {
    this.blankAudio.loop = true;
    PlaybackController.shared.wellnessEmitter.on('play', this.play);
    PlaybackController.shared.wellnessEmitter.on('pause', this.pause);
    this._ctx.addEventListener('statechange', () => {
      if (this._ctx.state === 'running') {
        this.blankAudio.play().then(() => {
          const topic = store
            .getState()
            .meditation.topics.find((t) => t.id === this._topicId);
          PlaybackController.shared.setNowPlaying(
            'wellness',
            new MediaMetadata({
              album: 'Wellness Music',
              artist: 'Mindfully',
              artwork: [
                {
                  src:
                    topic?.thumbnailUrl ??
                    `${process.env.PUBLIC_URL}/assets/images/logo.png`,
                },
              ],
              title: topic?.name ?? document.title,
            })
          );
        });
        this.onplay?.();

        return;
      }
      this.blankAudio.pause();
      this.onpause?.();
    });
    if (this._ctx.state === 'running') {
      this._ctx.suspend();
    }

    // this._handleMetadata = this._handleMetadata.bind(this);
    // this._pushChunkAsync = this._pushChunkAsync.bind(this);
    this.play = this.play.bind(this);
    this.pause = this.pause.bind(this);
    // this.stop = this.stop.bind(this);
    // Gate.instance.on('metadata', this._handleMetadata);
    // Gate.instance.on('play', this.play);
    // Gate.instance.on('pause', this.pause);
    // Gate.instance.on('chunk', this._pushChunkAsync);

    this._compressor = this._ctx.createDynamicsCompressor();

    this._compressor.knee.value = 18;
    this._compressor.attack.value = 0.00025;
    this._compressor.ratio.value = 4;
    this._compressor.release.value = 0.21;
    this._compressor.threshold.value = -18;
  }

  public setSources(sources: ISourceMetadata[]) {
    this.blankAudio.pause();
    this.onpause?.();
    if (!this._isFistPlay) {
      this.onLoadSource?.(sources);
    }
    this._isFistPlay = false;
    this._tracks = [...sources].sort((a, b) => a.begin - b.begin);
    this._nextTracks = undefined;

    this._session = _.uniqueId('media-session-');

    this._handleMetadata({ metadata: sources, session: this._session });
  }

  private async _handleBufferingAsync(session: string) {
    if (!this._tracks && session !== this._session) {
      return;
    }
    const tracks = _.unionBy(this._tracks, 'hash');
    let errorCount = 0;
    for (const track of tracks) {
      if (session !== this._session) {
        return;
      }
      try {
        // if cached => return cached buffer
        // if not => download file
        await downloadApi.download(track.url).then((buffer) => {
          this._pushChunkAsync({
            buffer,
            hash: track.hash,
            session,
          });
        });
      } catch (err) {
        errorCount++;
        console.error(err);
      }
    }
    if (errorCount === tracks.length) {
      this.onError?.('source-load-failed');
      return;
    }
    if (this._topicId) {
      this._nextTracks = await this._getNextMetadataAsync(this._topicId);
    }
  }

  private _handleBuffered(session: string) {
    if (session === this._session) {
      this.play();
    }
  }

  private _getNextMetadataAsync(topicId: number) {
    return functionalMusicApis
      .getMetadataFromTopic(topicId)
      .then((res) => {
        const metadata = res.clipEvents.map((item) => {
          return {
            end: item.end,
            begin: item.start,
            hash: md5(item.clipName),
            url: item.url,
          } as ISourceMetadata;
        });

        store.dispatch(MeditationActions.cacheMetadata(topicId, metadata));

        return metadata;
      })
      .catch(() => {
        const cached = store.getState().meditation.cached[topicId] ?? [];
        if (cached.length) {
          const idx = _.random(0, cached.length - 1, false);
          return cached[idx];
        }
      });
  }

  public play() {
    if (this._ctx?.state === 'suspended') {
      this._ctx.resume();
    }
  }
  public pause() {
    if (this._ctx?.state === 'running') {
      this._ctx.suspend();
    }
  }

  private async _handleMetadata(payload: {
    metadata: ISourceMetadata[];
    session: string;
  }) {
    await this._releaseAsync();
    this._session = payload.session;
    this._tracks = payload.metadata.sort((a, b) => a.end - b.end);
    const lastTrack = this._tracks[this._tracks.length - 1];
    const duration = lastTrack.end;
    this._lastTrack = lastTrack;

    this._firstTrackBeginTime = this._tracks[0].begin;
    this._tracks.forEach((track) => {
      if (track.begin < this._firstTrackBeginTime) {
        this._firstTrackBeginTime = track.begin;
      }
    });
    this._startedTime = this._ctx!.currentTime;
    this._gain = this._ctx.createGain();
    this._fadeInOutNode = this._ctx.createGain();

    this._gain.connect(this._compressor);
    this._compressor.connect(this._fadeInOutNode);

    this._fadeInOutNode.connect(this._ctx.destination);

    this._fadeInOutNode.gain.setValueCurveAtTime(
      [1, 0],
      this._startedTime + duration - TOTAL_FADE_OUT_TIME,
      3
    );
    setTimeout(() => {
      this._handleBufferingAsync(payload.session);
    });
  }

  private async _pushChunkAsync(source: IChunkData) {
    if (source.session !== this._session) {
      return;
    }
    const tracks = this._tracks?.filter((t) => t.hash === source.hash);
    if (!tracks?.length || !this._gain) {
      return;
    }
    try {
      // console.log(source.hash, source.buffer);

      const buffer = await this._ctx.decodeAudioData(source.buffer);
      splitTracks(tracks).forEach((path) => {
        const gain = this._ctx.createGain();
        gain.connect(this._gain!);
        // add fade in effect
        gain.gain.setValueCurveAtTime(
          [0, 1],
          this._startedTime + path.begin,
          TRACK_FADE_IN_TIME
        );
        // add fade out effect
        if (this.duration - path.end > TOTAL_FADE_OUT_TIME) {
          const startTime = Math.max(
            path.end - TRACK_FADE_OUT_TIME,
            path.begin + TRACK_FADE_IN_TIME
          );

          gain.gain.setValueCurveAtTime(
            [1, 0],
            this._startedTime + startTime,
            path.end - startTime
          );
        }
        path.tracks.forEach((track) => {
          if (track.loaded) {
            return;
          }
          const bufferSource = this._ctx!.createBufferSource();
          bufferSource.buffer = buffer;
          bufferSource.connect(gain);
          bufferSource.start(Math.max(0, track.begin + this._startedTime));

          track.loaded = true;
          if (source.hash === this._lastTrack?.hash) {
            this._lastSourceNode = bufferSource;
          }
        });
      });

      if (source.hash === this._lastTrack?.hash && this._lastSourceNode) {
        this._lastSourceNode.onended = () => {
          if (this._nextTracks && this._session === source.session) {
            this.setSources(this._nextTracks);
          }
        };
      }
      if (!this._sentReadyToPlay && this._session === source.session) {
        this._handleBuffered(source.session);
        this._sentReadyToPlay = true;
      }
    } catch (e: any) {
      console.error(e);
      this.onError?.(e?.message ?? e?.toString());
    }
  }

  private async _releaseAsync() {
    if (this._ctx.state === 'running') {
      await this._ctx.suspend();
    }
    this._compressor.disconnect();
    this._fadeInOutNode?.disconnect();
    this._gain?.disconnect();
    this._sentReadyToPlay = false;

    delete this._fadeInOutNode;
    delete this._tracks;
    delete this._lastTrack;
    delete this._lastSourceNode;
    delete this._gain;
  }
}

function splitTracks(tracks: ISourceMetadata[]) {
  const result: {
    begin: number;
    end: number;
    tracks: ISourceMetadata[];
    hash: string;
  }[] = [];
  tracks?.sort((a, b) => a.begin - b.begin);

  let startIdx = 0;
  for (let i = 0; i < tracks.length; i++) {
    const track = tracks[i];
    if (i === tracks.length - 1) {
      // is last track
      result.push({
        begin: tracks[startIdx].begin,
        end: track.end,
        tracks: tracks.slice(startIdx, tracks.length),
        hash: track.hash,
      });
      break;
    }
    const nextTrack = tracks[i + 1];

    if (track.end < nextTrack.begin) {
      result.push({
        begin: tracks[startIdx].begin,
        end: track.end,
        tracks: tracks.slice(startIdx, i + 1),
        hash: track.hash,
      });
      startIdx = i + 1;
    }
  }

  return result;
}
