import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Topic } from '../models/topic';
import { RootState } from '../redux';
import { MeditationActions } from '../redux/meditation';

export function useCurrentTopic() {
  const meditation = useSelector((state: RootState) => state.meditation);
  return meditation.topics.find((t) => t.id === meditation.currentTopic);
}

export function useSetCurrentTopic() {
  const dispatch = useDispatch();
  return useCallback(
    (topicId: number) => {
      return dispatch(MeditationActions.setCurrentTopic(topicId));
    },
    [dispatch]
  );
}

export function useTopicDetail() {
  return useSelector((state: RootState) => state.meditation.topicDetail);
}

export function useSetTopicDetail() {
  const dispatch = useDispatch();
  return useCallback(
    (topic?: Topic) => {
      return dispatch(MeditationActions.setTopicDetail(topic));
    },
    [dispatch]
  );
}
