import styled from '@emotion/styled';
import { Dialog, Switch } from '@mui/material';
import React from 'react';
import { WithTranslation, withTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import Button from '../../../../components/Button';
import { StyledFormControlLabel } from '../../../../components/FormItem';
import WheelPicker from '../../../../components/WheelPicker';
import storage from '../../../../helper/storage';
import { RootState } from '../../../../redux';
import { MeditationActions } from '../../../../redux/meditation';
import { capacityFirst } from '../../../../utils/string';

type Props = {
  open: boolean;
  onClose?: () => void;
  onSetTimer?: (mins: number) => void;
} & WithTranslation &
  ReturnType<typeof mapStateToProps> &
  ReturnType<typeof mapDispatchToProps>;

type State = {
  autoTurnOffDuration: number;
};

const mapStateToProps = (state: RootState) => ({
  meditation: state.meditation,
});

const mapDispatchToProps = (dispatch: Dispatch) =>
  bindActionCreators(
    {
      setTimerBell: MeditationActions.setTimerBell,
    },
    dispatch
  );

class TimerPopup extends React.Component<Props, State> {
  state = Object.freeze<State>({
    autoTurnOffDuration: storage.getTimerDuration(),
  });

  private _handlePickerChange = (v: number) => {
    this.setState({ autoTurnOffDuration: v });
  };

  private _formatMinute = (mins: number) => {
    const { t } = this.props;
    if (mins < 60) {
      return `${mins} ${t('mins')}`;
    }
    const h = Math.floor(mins / 60);
    const m = mins % 60;
    if (m) {
      return `${h} ${t(h > 1 ? 'hours' : 'hour')} ${m
        .toString()
        .padStart(2, '0')} ${t('mins')}`;
    }
    return `${h} ${t(h > 1 ? 'hours' : 'hour')}`;
  };

  private _handleOk = () => {
    this.props.onSetTimer?.(this.state.autoTurnOffDuration);
  };

  private _handleCancel = () => {
    this.props.onClose?.();
  };

  private _handleEndingBell = (
    event: React.SyntheticEvent<Element, Event>,
    checked: boolean
  ) => {
    this.props.setTimerBell(checked);
  };

  render() {
    return (
      <Dialog
        fullWidth
        maxWidth="sm"
        PaperProps={{
          style: {
            borderRadius: 16,
            padding: 16,
            backgroundColor: '#172B2A',
            fontFamily: 'inherit',
            color: 'inherit',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          },
        }}
        open={this.props.open}
      >
        <PopupTitle>
          {capacityFirst(this.props.t('setting'))}{' '}
          {capacityFirst(this.props.t('timer'))}
        </PopupTitle>
        <Divider />
        <AutoStopDescription>
          {capacityFirst(this.props.t('auto stop music after'))}:
        </AutoStopDescription>
        <PickerContainer>
          <WheelPicker
            options={durations}
            itemSize={48}
            optionKey={(i) => i}
            format={this._formatMinute}
            onChange={this._handlePickerChange}
            selectedValue={this.state.autoTurnOffDuration}
          />
        </PickerContainer>

        <Bounding>
          <StyledFormControlLabel
            value="start"
            checked={!!this.props.meditation.timerBell}
            onChange={this._handleEndingBell}
            control={<Switch size="small" color="primary" />}
            label={capacityFirst(this.props.t('ending sound'))}
            labelPlacement="start"
          />
        </Bounding>
        <PopupActions>
          <ActionButton onClick={this._handleCancel} color="secondary">
            {capacityFirst(this.props.t('cancel'))}
          </ActionButton>
          <ActionButton onClick={this._handleOk} color="primary">
            {capacityFirst(this.props.t('set timer'))}
          </ActionButton>
        </PopupActions>
      </Dialog>
    );
  }
}

function genarateOptions() {
  const result: number[] = [0.5];
  let i = 5;
  while (i <= 4 * 60) {
    result.push(i);
    i += i < 60 ? 5 : 10;
  }
  return result;
}

const durations = genarateOptions();

const PopupTitle = styled.div`
  font-weight: 600;
  font-size: 20px;
  line-height: 28px;
  text-align: center;
  padding: 32px;
  @media (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
    padding: 4px 16px 16px 16px;
  }
  @media (max-height: 600px) {
    padding-top: 16px;
    padding-bottom: 16px;
  }
`;

const Divider = styled.div`
  border-bottom: 1px solid #fff;
  max-width: 380px;
  width: 100%;
`;

const PickerContainer = styled.div`
  height: 250px;
  margin: 20px 0;
  width: 100%;
  max-width: 380px;
  display: flex;
  .wheel-picker-item-content {
    font-weight: 500;
    font-size: 20px;
    @media (max-width: 600px) {
      font-size: 18px;
    }
  }

  @media (max-height: 600px) {
    margin-top: 8px;
  }
`;

const AutoStopDescription = styled.div`
  margin-top: 20px;
  font-size: 16px;
  font-weight: 500;
`;

const PopupActions = styled.div`
  display: flex;
  justify-content: space-around;
  align-items: center;
  text-align: center;
  width: 100%;
  max-width: 380px;
  margin: 40px auto 20px auto;
  @media (max-height: 600px) {
    margin-top: 20px;
  }
`;

const Bounding = styled.div`
  max-width: 380px;
  padding: 4px 32px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.05);
  width: 100%;
  box-sizing: border-box;
`;

const ActionButton = styled(Button)`
  max-width: 170px;
  flex: 1;
  font-weight: 500;
  margin: 0 8px;
  &.MuiButton-textSecondary {
    background-color: rgba(255, 255, 255, 0.2);
    color: inherit;
  }
  &.MuiButton-textPrimary {
    background-color: rgba(255, 255, 255, 1);
    color: var(--monochrome-6);
  }
`;

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withTranslation()(TimerPopup));
