import styled from '@emotion/styled';
import { CircularProgress } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { ListPaginationResponse } from '../../../../../api/base';
import medioApi from '../../../../../api/medio';
import useQuery from '../../../../../hooks/use-query';
import { ContentType, FilterType } from '../../../../../models/collection';
import { Medio } from '../../../../../models/medio';
import { RootState } from '../../../../../redux';
import ContentItem from './ContentItem';
import TopicItem from './TopicItem';

type Props = {
  tab: {
    key: string;
    title: string;
    tags: string[];
    collection?: number;
    contentTypes?: ContentType[];
    filterType: FilterType;
  };
};

export default function TabContent(props: Props) {
  const query = useQuery();
  const shouldVisible = query.tab === props.tab.key;
  const [mounted, setMounted] = useState(shouldVisible);

  useEffect(() => {
    if (shouldVisible && !mounted) {
      setMounted(true);
    }
  }, [shouldVisible, mounted]);

  if (!mounted) {
    return null;
  }

  return (
    <Root visible={shouldVisible}>
      <ListView config={props.tab} />
    </Root>
  );
}

const listDefault: ListPaginationResponse<Medio> = {
  items: [],
  totalItems: 0,
  totalPages: 0,
  pageIndex: 0,
};

function ListView(props: {
  config: {
    title: string;
    tags: string[];
    collection?: number;
    contentTypes?: ContentType[];
    filterType: FilterType;
    isWellness?: boolean;
  };
}) {
  const [list, setList] = useState<ListPaginationResponse<Medio>>(listDefault);
  const [loading, setLoading] = useState(false);
  const topics = useSelector((state: RootState) => state.meditation.topics);

  useEffect(() => {
    if (props.config.isWellness) {
      return;
    }
    setLoading(true);
    medioApi
      .getMedioList({
        filterType: props.config.filterType,
        tagNames: props.config.tags,
        contentTypes: props.config.contentTypes,
        collectionId: props.config.collection,
        pgLimit: 100,
      })
      .then(setList)
      .finally(() => {
        setLoading(false);
      });
  }, [props.config]);

  if (loading) {
    return (
      <div className="centering">
        <CircularProgress size={32} color="inherit" />
      </div>
    );
  }

  if (props.config.isWellness ? !topics.length : !list.items.length) {
    return <div style={{ textAlign: 'center' }}>Content is empty</div>;
  }

  return (
    <ListRoot>
      {props.config.isWellness
        ? topics.map((topic) => {
            return (
              <Item key={topic.id}>
                <TopicItem topic={topic} />
              </Item>
            );
          })
        : list.items.map((item) => (
            <Item key={item.id}>
              <ContentItem data={item} />
            </Item>
          ))}
    </ListRoot>
  );
}

const Root = styled.div<{ visible: boolean }>`
  display: ${({ visible }) => (visible ? 'block' : 'none')};
`;

const ListRoot = styled.ul`
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  padding: 0;
  @media (max-width: 681px) {
    justify-content: center;
  }
`;

const Item = styled.li`
  margin: 8px;
`;
