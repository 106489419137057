import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import IconButtonWithLabel from '../../components/IconButtonWithLabel';
import {
  MusicNoteIcon,
  PauseIcon,
  PlayIcon,
  ReloadIcon,
  SearchIcon,
  TimerIcon,
} from '../../components/icons';
import { useCurrentMedio, usePlayerState } from '../../hooks/player';
import MusicPlayer from '../../controllers/MusicPlayer';
import { capacityFirst } from '../../utils/string';
// import MusicPlayer from '../../player/MusicPlayer';
import HeaderBar from './components/HeaderBar';
import LibraryPopup from './components/library';
import MedioDetail from './components/popups/MedioDetail';
import TimerPopup from './components/popups/TimerPopup';
import {
  ContentTypeRow,
  ControlPaneContainer,
  CurrentMeditationContainer,
  FooterContainer,
  MeditationTitle,
  Root,
  TopicDescription,
} from './styled';
import Timer from '../../controllers/Timer';
import storage from '../../helper/storage';
import TimerCounter from './components/TimerCounter';
import { useTimerIsOn } from '../../hooks/timer';
import { useDispatch } from 'react-redux';
import { useCurrentTopic } from '../../hooks/meditation';
import Background from './components/Background';
import useQuery from '../../hooks/use-query';
import { useNavigate } from 'react-router-dom';
import Footer from './components/Footer';
import FeedbackPopup from './components/popups/FeedbackPopup';
import Login from '../login';
import Private from '../../components/Private';
import { authAction } from '../../helper/auth';
import { createSearchQuery } from '../../utils/query';
import TopicDetail from './components/popups/TopicDetail';
import AboutUsPopup from './components/popups/AboutUsPopup';
import LanguagePopup from './components/popups/LanguagePopup';
import { CircularProgress } from '@mui/material';

type Props = {};

export default function HomeRouting(props: Props) {
  const { t } = useTranslation();
  const [timerPopupOpen, setTimerPopupOpen] = useState(false);
  const currentMedio = useCurrentMedio();
  const currentTopic = useCurrentTopic();
  const playerState = usePlayerState();
  const isTimerOn = useTimerIsOn();
  const dispatch = useDispatch();
  const query = useQuery();
  const navigate = useNavigate();
  const [detailOpen, setDetailOpen] = useState(false);
  const [explorerOpen, setExplorerOpen] = useState(false);

  const PlayerStateIcon = playerState === 'play' ? PauseIcon : PlayIcon;

  useEffect(() => {
    const handler = (ev: KeyboardEvent) => {
      const el = ev.target as HTMLElement;
      if (
        ev.key === ' ' &&
        el?.tagName?.toLowerCase() !== 'button' &&
        el?.tagName?.toLowerCase() !== 'textarea' &&
        el?.tagName?.toLowerCase() !== 'input'
      ) {
        if (MusicPlayer.state === 'play') {
          MusicPlayer.pause();
        } else {
          MusicPlayer.play();
        }
      }
    };

    window.addEventListener('keydown', handler);

    return () => {
      MusicPlayer.pause();
      window.removeEventListener('keydown', handler);
    };
  }, [dispatch]);

  useEffect(() => {
    if (query.medio) {
      setDetailOpen(true);
    }
  }, [query.medio]);

  useEffect(() => {
    if (currentTopic?.id && !currentMedio) {
      MusicPlayer.setTopic(currentTopic.id);
    }
  }, [currentMedio, currentTopic?.id]);

  return (
    <Root>
      <Background />
      <HeaderBar />
      <CurrentMeditationContainer>
        {!!currentMedio && (
          <>
            <ContentTypeRow>
              {t(currentMedio?.contentType ?? '')}
            </ContentTypeRow>
            <MeditationTitle>{currentMedio?.title}</MeditationTitle>
          </>
        )}
        {!!currentTopic && !currentMedio && (
          <TopicDescription>{currentTopic.description}</TopicDescription>
        )}
      </CurrentMeditationContainer>
      <div className="expanded" />
      <TimerCounter />
      <ControlPaneContainer>
        {/* <IconButtonWithLabel
          label={capacityFirst(t('music only'))}
          icon={<MusicNoteIcon fontSize="inherit" />}
          disabled={!currentMedio || !currentTopic}
          onClick={() => {
            if (currentTopic) {
              MusicPlayer.playTopicMusic(currentTopic?.id);
            }
          }}
        /> */}
        <IconButtonWithLabel
          animation
          label={capacityFirst(t('reframe'))}
          icon={<ReloadIcon fontSize="inherit" />}
          disabled={!!currentMedio || !currentTopic}
          onClick={() => {
            if (!currentMedio) {
              MusicPlayer.refreshTopicMusic();
            }
          }}
        />
        <IconButtonWithLabel
          disabled={
            (!currentMedio && !currentTopic) || playerState === 'loading'
          }
          label={capacityFirst(
            t(
              playerState === 'loading'
                ? 'loading'
                : playerState === 'play'
                ? 'pause'
                : 'play'
            )
          )}
          activated
          icon={
            playerState === 'loading' ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              <PlayerStateIcon fontSize="inherit" />
            )
          }
          onClick={() => {
            if (MusicPlayer.state === 'play') {
              MusicPlayer.pause();
              return;
            }
            MusicPlayer.play();
          }}
        />
        <IconButtonWithLabel
          label={capacityFirst(t('timer'))}
          icon={<TimerIcon fontSize="inherit" />}
          activated={isTimerOn}
          onClick={() => {
            if (isTimerOn) {
              Timer.stop();
              return;
            }
            setTimerPopupOpen(true);
          }}
        />
        {/* <IconButtonWithLabel
          onClick={() =>
            authAction(() => {
              setExplorerOpen(true);
            })
          }
          label={capacityFirst(t('explorer'))}
          icon={<SearchIcon fontSize="inherit" />}
        /> */}
      </ControlPaneContainer>
      <FooterContainer>
        <Footer />
      </FooterContainer>
      <TimerPopup
        open={timerPopupOpen}
        onClose={() => setTimerPopupOpen(false)}
        onSetTimer={(mins) => {
          Timer.start(mins);
          storage.setTimerDuration(mins);
          setTimerPopupOpen(false);
        }}
      />
      <Private active={explorerOpen}>
        <LibraryPopup
          open={explorerOpen}
          onClose={() => {
            setExplorerOpen(false);
          }}
          onMedioInfo={() => {
            if (query.medio === currentMedio?.id.toString()) {
              setDetailOpen(true);
              return;
            }
            navigate({
              search: createSearchQuery({
                medio: currentMedio?.id,
              }),
            });
          }}
        />
      </Private>
      <TopicDetail />
      <Private active={detailOpen}>
        <MedioDetail
          open={detailOpen}
          onClose={() => {
            setDetailOpen(false);
            navigate({
              search: createSearchQuery({
                medio: undefined,
              }),
            });
          }}
        />
      </Private>
      <AboutUsPopup />
      <LanguagePopup />
      <FeedbackPopup />
      <Login />
    </Root>
  );
}
