import { mdiClose } from '@mdi/js';
import { Dialog } from '@mui/material';
import { signInWithPopup, UserCredential } from 'firebase/auth';
import { t } from 'i18next';
import { useState } from 'react';
import { Trans } from 'react-i18next';
import { connect, useDispatch } from 'react-redux';
import authApi from '../../api/auth';
import Button from '../../components/Button';
import { EmailIcon, MdiIcon } from '../../components/icons';
import {
  appleProvider,
  auth,
  facebookProvider,
  googleProvider,
} from '../../firebase';
import storage from '../../helper/storage';
import { useLoginPopupOpen, useSetLoginPopupOpen } from '../../hooks/auth';
import { useIsMobile } from '../../hooks/layout';
import { SocialProviderEnum } from '../../models/auth';
import { RootState } from '../../redux';
import { AuthActions } from '../../redux/auth';
import { UserActions } from '../../redux/user';
import EmailSentMessage from './EmailSentMessage';
import { Root, SocialLoginContainer, SocialLogo } from './styled';

interface ErrorObj {
  [key: string]: string | undefined;
}

type P = ReturnType<typeof mapStateToProps>;

const LoginPage = (props: P) => {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [errors, setErrors] = useState<ErrorObj>({});
  const loginPopupOpen = useLoginPopupOpen();
  const [isEnterEmail, setIsEnterEmail] = useState(false);
  const setLoginPopupOpen = useSetLoginPopupOpen();
  const isMobile = useIsMobile();
  const isAuth = props.auth.mindfullyAuth && props.auth.firebaseUser;

  function clearError(field: string) {
    const newErrors = { ...errors };
    delete newErrors[field];
    setErrors(newErrors);
  }

  function loginWithUserCredential(
    userCredential: UserCredential,
    provider: SocialProviderEnum
  ) {
    return userCredential.user
      .getIdToken()
      .then((token) => {
        dispatch(AuthActions.setIsAuthenticating(true));
        return authApi.login(token, provider);
      })
      .then((res) => {
        if (res.tokens) {
          storage.setTokens(res.tokens);
          dispatch(AuthActions.setAuth(true));
          if (res.profile) {
            dispatch(UserActions.setProfile(res.profile));
          }
          return;
        }
        throw Error('authenticate-failed');
      })
      .catch((error) => {
        console.error(error);
        setErrors({
          all: t(error?.error?.message ?? error?.message ?? 'Login error'),
        });
      })
      .finally(() => {
        dispatch(AuthActions.setIsAuthenticating(false));
      });
  }

  const loginWithGoogle = () => {
    clearError('all');
    setIsLoading(true);
    signInWithPopup(auth, googleProvider)
      .then((result) =>
        loginWithUserCredential(result, SocialProviderEnum.GOOGLE)
      )

      .finally(() => {
        setIsLoading(false);
      });
  };
  const loginWithFacebook = () => {
    clearError('all');
    setIsLoading(true);
    signInWithPopup(auth, facebookProvider)
      .then((result) =>
        loginWithUserCredential(result, SocialProviderEnum.FACEBOOK)
      )

      .finally(() => {
        setIsLoading(false);
      });
  };
  const loginWithApple = () => {
    clearError('all');
    setIsLoading(true);
    signInWithPopup(auth, appleProvider)
      .then((result) =>
        loginWithUserCredential(result, SocialProviderEnum.APPLE)
      )

      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isAuth) {
    return null;
  }

  return (
    <Dialog
      open={loginPopupOpen}
      PaperProps={{
        sx: {
          background: '#1A4240',
          color: 'inherit',
          width: '100%',
          m: 2,
        },
      }}
      fullWidth={isMobile}
      maxWidth={'xs'}
    >
      <Root>
        <div
          style={{
            textAlign: 'right',
          }}
        >
          <Button
            disableRipple
            color="inherit"
            disabled={isLoading}
            sx={{ minWidth: 0 }}
            onClick={() => {
              setLoginPopupOpen(false);
              setIsEnterEmail(false);
            }}
          >
            <MdiIcon d={mdiClose} color="inherit" />
          </Button>
        </div>
        {isEnterEmail ? (
          <EmailSentMessage onBack={() => setIsEnterEmail(false)} />
        ) : (
          <>
            <SocialLoginContainer>
              {false && (
                <Button
                  className="social-button"
                  variant="outlined"
                  disabled={isLoading}
                  onClick={loginWithApple}
                  startIcon={
                    <SocialLogo
                      src={`${process.env.PUBLIC_URL}/assets/images/socials/apple.svg`}
                    />
                  }
                >
                  <Trans>Continue with</Trans> Apple
                </Button>
              )}
              <Button
                className="social-button"
                variant="outlined"
                disabled={isLoading}
                onClick={loginWithGoogle}
                startIcon={
                  <SocialLogo
                    src={`${process.env.PUBLIC_URL}/assets/images/socials/google.svg`}
                  />
                }
              >
                <span className="social-button-text">
                  <Trans>Continue with</Trans> Google
                </span>
              </Button>
              <Button
                className="social-button"
                variant="outlined"
                disabled={isLoading}
                onClick={loginWithFacebook}
                startIcon={
                  <SocialLogo
                    src={`${process.env.PUBLIC_URL}/assets/images/socials/facebook.svg`}
                  />
                }
              >
                <span className="social-button-text">
                  <Trans>Continue with</Trans> Facebook
                </span>
              </Button>
              <Button
                className="social-button"
                variant="outlined"
                disabled={isLoading}
                onClick={() => setIsEnterEmail(true)}
                color="inherit"
                startIcon={
                  <EmailIcon
                    sx={{
                      width: 32,
                      height: 32,
                    }}
                    color="inherit"
                  />
                }
              >
                <span className="social-button-text">
                  <Trans>Continue with</Trans> email
                </span>
              </Button>
            </SocialLoginContainer>
          </>
        )}
      </Root>
    </Dialog>
  );
};

const mapStateToProps = (state: RootState) => ({
  auth: state.auth,
});

export default connect(mapStateToProps)(LoginPage);
