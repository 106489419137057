import React, { useMemo } from 'react';
import { useLanguage } from '../../../../../hooks/app';
import en from './about-en.txt';
import vi from './about-vi.txt';

type Props = {};

const resources: { [lang: string]: string } = {
  vi,
  en,
};

export default React.memo(function About(props: Props) {
  const lang = useLanguage();
  const content = useMemo(() => {
    return (resources[lang] ?? resources['en'])
      .replace(/(\r?\n)+\s*/g, '\n')
      .split('\n');
  }, [lang]);

  return (
    <>
      {content.map((p) => (
        <p>{p}</p>
      ))}
    </>
  );
});
