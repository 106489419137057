import { useEffect, useState } from 'react';
import Timer from '../controllers/Timer';

export function useTimerIsOn() {
  const [isOn, setIsOn] = useState(Timer.running);
  useEffect(() => {
    const startListener = () => {
      setIsOn(true);
    };
    const stopListener = () => {
      setIsOn(false);
    };
    Timer.on('started', startListener);
    Timer.on('stopped', stopListener);
    return () => {
      Timer.off('started', startListener);
      Timer.off('stopped', stopListener);
    };
  }, []);
  return isOn;
}
