import styled from '@emotion/styled';
import React, { useEffect, useRef } from 'react';
import MusicPlayer from '../../../controllers/MusicPlayer';
import { useCurrentTopic } from '../../../hooks/meditation';
import { useCurrentMedio, usePlayerState } from '../../../hooks/player';

type Props = {};

export default function Background(props: Props) {
  const currentMedio = useCurrentMedio();
  const currentTopic = useCurrentTopic();
  const videoRef = useRef<HTMLVideoElement>(null);
  const playerState = usePlayerState();

  useEffect(() => {
    function updateBgVideoState() {
      if (videoRef.current?.paused && MusicPlayer.state === 'play') {
        videoRef.current?.play();
      }

      if (
        !videoRef.current?.paused &&
        (MusicPlayer.state === 'pause' || MusicPlayer.state === 'loading')
      ) {
        videoRef.current?.pause();
      }
    }
    updateBgVideoState();
  }, [currentMedio, playerState]);

  return (
    <>
      {!!currentMedio && <BackgroundImage src={currentMedio.photoURL} />}
      {!currentMedio && currentTopic && (
        <BackgroundVideo
          ref={videoRef}
          src={currentTopic.visualUrl}
          muted
          autoPlay={playerState === 'play'}
          loop
          playsInline
        />
      )}
      <Mask />
    </>
  );
}

const BackgroundVideo = styled.video`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  opacity: 1;
  z-index: 0;
`;

const BackgroundImage = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  object-fit: cover;
  opacity: 1;
  background-size: cover;
  background-position: center;
  background-image: url('${(props: { src?: string }) => props.src}');
  mask-image: radial-gradient(
    circle,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0) 80%
  );
  mask-size: 100% 150%;
`;

const Mask = styled.div`
  /* z-index: 1; */
  display: block;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-image: linear-gradient(
    0deg,
    rgba(0, 28, 26, 0.8) 0%,
    rgba(0, 12, 11, 0.05) 50%,
    rgba(0, 28, 26, 0.8) 100%
  );
  @media (min-width: 600px) {
    :after {
      display: block;
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
      background-image: linear-gradient(
        90deg,
        rgba(0, 28, 26, 0.8) 0%,
        rgba(0, 12, 11, 0.05) 50%,
        rgba(0, 28, 26, 0.8) 100%
      );
    }
  }
`;
